<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h2 class="mb-0 ml-4">Editar Cliente</h2>
              </div>
              <div class="col-4 text-right" v-if="!isInsideModal">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Voltar para lista</base-button
                >
              </div>
            </div>
            <div class="card-body">
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom">Dados Gerais:</h3>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="codclienteInput"
                    label="Cod. do Cliente"
                    prepend-icon="fas fa-user"
                    v-model="cliente.codcliente"
                    v-mask="'####'"
                  />

                  <validation-error :errors="apiValidationErrors.codcliente" />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="cpfcnpjInput"
                    label="CPF/CNPJ"
                    prepend-icon="fas fa-user"
                    v-model="cliente.cpfcnpj"
                    @change="selecionapessoajuridicatipo()"
                    v-mask="mask()"
                  />

                  <validation-error :errors="apiValidationErrors.cpfcnpj" />
                </div>
                <div class="col-md-4">
                  <base-input label="Data de Nascimento">
                    <flat-picker
                      :tabindex="0"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        altInput: true,
                        altFormat: 'd/m/Y',
                      }"
                      class="form-control datepicker"
                      v-model="cliente.datanascimento"
                    >
                    </flat-picker>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.datanascimento"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input label="Tipo de Cliente">
                    <el-select
                      name="pessoavinculo"
                      ref="pessoavinculoInput"
                      :tabindex="0"
                      v-model="cliente.pessoavinculo.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                    >
                      <el-option
                        v-for="single_pessoavinculo in all_pessoavinculos"
                        :key="single_pessoavinculo.id"
                        :value="single_pessoavinculo.id"
                        :label="
                          preferencia &&
                          preferencia.pessoavinculo &&
                          single_pessoavinculo.id ===
                            preferencia.pessoavinculo.id
                            ? `★ ${single_pessoavinculo.name}`
                            : single_pessoavinculo.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.pessoavinculo"
                  />
                  <div ref="display_pessoavinculo"></div>
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="nomeInput"
                    label="Nome"
                    prepend-icon="fas fa-user"
                    v-model="cliente.nome"
                  />

                  <validation-error :errors="apiValidationErrors.nome" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    label="Tipo"
                    :disabled="true"
                    v-if="cliente.tipopessoa.id == null"
                  >
                    <el-select
                      name="tipopessoa"
                      ref="tipopessoaInput"
                      :tabindex="0"
                      v-model="cliente.tipopessoa.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      @change="selecionapessoajuridicatipo()"
                      :disabled="true"
                      v-if="cliente.tipopessoa.id == null"
                    >
                      <el-option
                        v-for="single_tipopessoa in all_tipopessoas"
                        :key="single_tipopessoa.id"
                        :value="single_tipopessoa.id"
                        :label="
                          preferencia &&
                          preferencia.tipopessoa &&
                          single_tipopessoa.id === preferencia.tipopessoa.id
                            ? `★ ${single_tipopessoa.name}`
                            : single_tipopessoa.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.tipopessoa" />
                  <div ref="display_tipopessoa"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    label="Tipo Empresa"
                    :disabled="
                      cliente.tipopessoa.id == 1 &&
                      cliente.pessoajuridicatipo.id == 4
                    "
                    v-if="cliente.tipopessoa.id != 1"
                  >
                    <el-select
                      name="pessoajuridicatipo"
                      ref="pessoajuridicatipoInput"
                      :tabindex="0"
                      v-model="cliente.pessoajuridicatipo.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      :disabled="
                        cliente.tipopessoa.id == 1 &&
                        cliente.pessoajuridicatipo.id == 4
                      "
                      v-if="cliente.tipopessoa.id != 1"
                    >
                      <el-option
                        v-for="single_pessoajuridicatipo in all_pessoajuridicatipos"
                        :key="single_pessoajuridicatipo.id"
                        :value="single_pessoajuridicatipo.id"
                        :label="
                          preferencia &&
                          preferencia.pessoajuridicatipo &&
                          single_pessoajuridicatipo.id ===
                            preferencia.pessoajuridicatipo.id
                            ? `★ ${single_pessoajuridicatipo.name}`
                            : single_pessoajuridicatipo.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.pessoajuridicatipo"
                  />
                  <div ref="display_pessoajuridicatipo"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    label="Estado Civil"
                    :disabled="cliente.pessoavinculo.id == 1"
                  >
                    <el-select
                      name="estadocivil"
                      ref="estadocivilInput"
                      :tabindex="0"
                      v-model="cliente.estadocivil.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      :disabled="cliente.pessoavinculo.id == 1"
                    >
                      <el-option
                        v-for="single_estadocivil in all_estadocivils"
                        :key="single_estadocivil.id"
                        :value="single_estadocivil.id"
                        :label="
                          preferencia &&
                          preferencia.estadocivil &&
                          single_estadocivil.id === preferencia.estadocivil.id
                            ? `★ ${single_estadocivil.name}`
                            : single_estadocivil.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.estadocivil" />
                  <div ref="display_estadocivil"></div>
                </div>
                <div class="col-md-4">
                  <base-input
                    label="Sexo"
                    :disabled="cliente.pessoavinculo.id == 1"
                  >
                    <el-select
                      name="pessoasexo"
                      ref="pessoasexoInput"
                      :tabindex="0"
                      v-model="cliente.pessoasexo.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      :disabled="cliente.pessoavinculo.id == 1"
                    >
                      <el-option
                        v-for="single_pessoasexo in all_pessoasexos"
                        :key="single_pessoasexo.id"
                        :value="single_pessoasexo.id"
                        :label="
                          preferencia &&
                          preferencia.pessoasexo &&
                          single_pessoasexo.id === preferencia.pessoasexo.id
                            ? `★ ${single_pessoasexo.name}`
                            : single_pessoasexo.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.pessoasexo" />
                  <div ref="display_pessoasexo"></div>
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3
                  class="border-bottom"
                  v-if="
                    cliente.pessoavinculo.id == 3 ||
                    cliente.pessoavinculo.id == 2
                  "
                >
                  Contato
                </h3>
              </div>
              <div
                class="row"
                v-if="
                  cliente.pessoavinculo.id == 3 || cliente.pessoavinculo.id == 2
                "
              ></div>

              <div
                class="row"
                v-if="
                  cliente.pessoavinculo.id == 3 || cliente.pessoavinculo.id == 2
                "
              >
                <div class="col-md-4">
                  <base-input
                    label="Tipo de Telefone"
                    :disabled="cliente.pessoavinculo.id == 1"
                  >
                    <el-select
                      name="tipotelefone"
                      ref="tipotelefoneInput"
                      :tabindex="0"
                      v-model="cliente.tipotelefone.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      @change="changetipotel()"
                      :disabled="cliente.pessoavinculo.id == 1"
                    >
                      <el-option
                        v-for="single_tipotelefone in all_tipotelefones"
                        :key="single_tipotelefone.id"
                        :value="single_tipotelefone.id"
                        :label="
                          preferencia &&
                          preferencia.tipotelefone &&
                          single_tipotelefone.id === preferencia.tipotelefone.id
                            ? `★ ${single_tipotelefone.name}`
                            : single_tipotelefone.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.tipotelefone"
                  />
                  <div ref="display_tipotelefone"></div>
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="telefoneInput"
                    label="Telefone"
                    prepend-icon="fas fa-user"
                    v-model="cliente.telefone"
                    v-mask="masktel()"
                    :disabled="
                      cliente.tipotelefone.id == 4 ||
                      cliente.tipotelefone.id < 1 ||
                      cliente.pessoavinculo.id == 1
                    "
                  />

                  <validation-error :errors="apiValidationErrors.telefone" />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="emailInput"
                    label="E-mail"
                    prepend-icon="fas fa-user"
                    v-model="cliente.email"
                    :disabled="cliente.pessoavinculo.id == 1"
                  />

                  <validation-error :errors="apiValidationErrors.email" />
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3
                  class="border-bottom"
                  v-if="
                    cliente.pessoavinculo.id == 3 ||
                    cliente.pessoavinculo.id == 1
                  "
                >
                  Endereço
                </h3>
              </div>
              <div
                class="row"
                v-if="
                  cliente.pessoavinculo.id == 3 || cliente.pessoavinculo.id == 1
                "
              ></div>

              <div
                class="row"
                v-if="
                  cliente.pessoavinculo.id == 3 || cliente.pessoavinculo.id == 1
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="cepInput"
                    label="CEP"
                    prepend-icon="fas fa-user"
                    v-model="cliente.cep"
                    @change="buscarcep()"
                    v-mask="'##.###-###'"
                    v-if="
                      cliente.pessoavinculo.id == 1 ||
                      cliente.pessoavinculo.id == 3
                    "
                  />

                  <validation-error :errors="apiValidationErrors.cep" />
                </div>
                <div class="col-md-4">
                  <base-input
                    label="tipo logradouro"
                    :disabled="
                      (apiValidationErrors.cep === null &&
                        cliente.logradourotipo.id !== null) ||
                      cliente.pessoavinculo.id == 2
                    "
                  >
                    <el-select
                      name="logradourotipo"
                      ref="logradourotipoInput"
                      :tabindex="0"
                      v-model="cliente.logradourotipo.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      :disabled="
                        (apiValidationErrors.cep === null &&
                          cliente.logradourotipo.id !== null) ||
                        cliente.pessoavinculo.id == 2
                      "
                    >
                      <el-option
                        v-for="single_logradourotipo in all_logradourotipos"
                        :key="single_logradourotipo.id"
                        :value="single_logradourotipo.id"
                        :label="
                          preferencia &&
                          preferencia.logradourotipo &&
                          single_logradourotipo.id ===
                            preferencia.logradourotipo.id
                            ? `★ ${single_logradourotipo.name}`
                            : single_logradourotipo.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.logradourotipo"
                  />
                  <div ref="display_logradourotipo"></div>
                </div>
              </div>

              <div
                class="row"
                v-if="
                  cliente.pessoavinculo.id == 3 || cliente.pessoavinculo.id == 1
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="logradouroInput"
                    label="Logradouro"
                    prepend-icon="fas fa-user"
                    v-model="cliente.logradouro"
                    :readonly="
                      apiValidationErrors.cep === null &&
                      cliente.logradouro.length > 3
                    "
                    v-if="
                      cliente.pessoavinculo.id == 1 ||
                      cliente.pessoavinculo.id == 3
                    "
                  />

                  <validation-error :errors="apiValidationErrors.logradouro" />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="bairroInput"
                    label="bairro"
                    prepend-icon="fas fa-user"
                    v-model="cliente.bairro"
                    :readonly="
                      apiValidationErrors.cep === null &&
                      cliente.bairro.length > 3
                    "
                    v-if="
                      cliente.pessoavinculo.id == 1 ||
                      cliente.pessoavinculo.id == 3
                    "
                  />

                  <validation-error :errors="apiValidationErrors.bairro" />
                </div>
                <div class="col-md-4">
                  <base-input
                    label="cidade"
                    :disabled="
                      (apiValidationErrors.cep === null &&
                        cliente.municipio.id !== null) ||
                      cliente.pessoavinculo.id == 2
                    "
                  >
                    <el-select
                      name="municipio"
                      ref="municipioInput"
                      :tabindex="0"
                      v-model="cliente.municipio.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                      :disabled="
                        (apiValidationErrors.cep === null &&
                          cliente.municipio.id !== null) ||
                        cliente.pessoavinculo.id == 2
                      "
                    >
                      <el-option
                        v-for="single_municipio in all_municipios"
                        :key="single_municipio.id"
                        :value="single_municipio.id"
                        :label="
                          preferencia &&
                          preferencia.municipio &&
                          single_municipio.id === preferencia.municipio.id
                            ? `★ ${single_municipio.name}`
                            : single_municipio.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.municipio" />
                  <div ref="display_municipio"></div>
                </div>
              </div>

              <div
                class="row"
                v-if="
                  cliente.pessoavinculo.id == 3 || cliente.pessoavinculo.id == 1
                "
              >
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="numeroInput"
                    label="nº"
                    prepend-icon="fas fa-user"
                    v-model="numeroFormatado"
                    v-if="
                      cliente.pessoavinculo.id == 1 ||
                      cliente.pessoavinculo.id == 3
                    "
                  />

                  <validation-error :errors="apiValidationErrors.numero" />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="complementoInput"
                    label="Complemento"
                    prepend-icon="fas fa-user"
                    v-model="cliente.complemento"
                    v-if="
                      cliente.pessoavinculo.id == 1 ||
                      cliente.pessoavinculo.id == 3
                    "
                  />

                  <validation-error :errors="apiValidationErrors.complemento" />
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom"></h3>
              </div>
              <div class="row"></div>

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Salvar Alterações
                </base-button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from "@/components/BaseSlider";
import Modal from "@/components/Modal.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from "vue-the-mask";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    insideModalOnSave: {
      type: String,
      required: false,
      default: null,
    },
  },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,

      all_pessoavinculos: [],
      all_tipopessoas: [],
      all_pessoajuridicatipos: [],
      all_estadocivils: [],
      all_pessoasexos: [],
      all_tipotelefones: [],
      all_logradourotipos: [],
      all_municipios: [],
      preferencia: {},

      cliente: {
        type: "clientes",

        codcliente: "",
        cpfcnpj: "",
        datanascimento: "",
        nome: "",
        telefone: "",
        email: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",

        pessoavinculo: {
          type: "pessoavinculos",
          id: null,
        },
        tipopessoa: {
          type: "tipopessoas",
          id: null,
        },
        pessoajuridicatipo: {
          type: "pessoajuridicatipos",
          id: null,
        },
        estadocivil: {
          type: "estadocivils",
          id: null,
        },
        pessoasexo: {
          type: "pessoasexos",
          id: null,
        },
        tipotelefone: {
          type: "tipotelefones",
          id: null,
        },
        logradourotipo: {
          type: "logradourotipos",
          id: null,
        },
        municipio: {
          type: "municipios",
          id: null,
        },
        relationshipNames: [
          "pessoavinculo",
          "tipopessoa",
          "pessoajuridicatipo",
          "estadocivil",
          "pessoasexo",
          "tipotelefone",
          "logradourotipo",
          "municipio",
          "proprietarios",
        ],
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["pessoavinculo","tipopessoa","pessoajuridicatipo","estadocivil","pessoasexo","tipotelefone","logradourotipo","municipio"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    numeroFormatado: {
      get() {
        return this.numToBr(this.cliente.numero);
      },
      set(valor) {
        this.cliente.numero = this.numToUs(valor);
      },
    },
  },
  watch: {},
  created() {
    this.get();

    this.getPessoavinculo();
    this.getTipopessoa();
    this.getPessoajuridicatipo();
    this.getEstadocivil();
    this.getPessoasexo();
    this.getTipotelefone();
    this.getLogradourotipo();
    this.getMunicipio();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("clientes/get", id);

        let apiData = this.$store.getters["clientes/cliente"];

        for (let key in apiData) {
          if (apiData[key] === null && this.cliente[key]?.id == null) {
            apiData[key] = this.cliente[key];
          }
        }
        this.cliente = apiData;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getPessoavinculo(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.pessoavinculos_filter_itens
              ? this.pessoavinculos_filter_itens
              : {}),
          },
        };
        //if(this.all_pessoavinculos || Object.keys(this.all_pessoavinculos).lenght == undefined){
        await this.$store.dispatch("pessoavinculos/list", params);
        this.all_pessoavinculos = await this.$store.getters[
          "pessoavinculos/dropdown"
        ];
        //}

        if (this.all_pessoavinculos && this.all_pessoavinculos.length) {
          // this.cliente.pessoavinculo.id = this.all_pessoavinculos[0].id;
          if (idn !== null) {
            this.cliente.pessoavinculo.id = idn;
            this.$refs.pessoavinculoInput.$emit(
              "change",
              this.cliente.pessoavinculo.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getTipopessoa(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.tipopessoas_filter_itens
              ? this.tipopessoas_filter_itens
              : {}),
          },
        };
        //if(this.all_tipopessoas || Object.keys(this.all_tipopessoas).lenght == undefined){
        await this.$store.dispatch("tipopessoas/list", params);
        this.all_tipopessoas = await this.$store.getters[
          "tipopessoas/dropdown"
        ];
        //}

        if (this.all_tipopessoas && this.all_tipopessoas.length) {
          // this.cliente.tipopessoa.id = this.all_tipopessoas[0].id;
          if (idn !== null) {
            this.cliente.tipopessoa.id = idn;
            this.$refs.tipopessoaInput.$emit(
              "change",
              this.cliente.tipopessoa.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getPessoajuridicatipo(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.pessoajuridicatipos_filter_itens
              ? this.pessoajuridicatipos_filter_itens
              : {}),
          },
        };
        //if(this.all_pessoajuridicatipos || Object.keys(this.all_pessoajuridicatipos).lenght == undefined){
        await this.$store.dispatch("pessoajuridicatipos/list", params);
        this.all_pessoajuridicatipos = await this.$store.getters[
          "pessoajuridicatipos/dropdown"
        ];
        //}

        if (typeof this.cliente.tipopessoa.id != "undefined") {
          //filtro baseado em campo select
          var tipopessoa_at = this.cliente.tipopessoa.id;
          function filtra_pessoajuridicatipo(value) {
            if (value.tipopessoa_id == tipopessoa_at) {
              return value;
            }
          }
          this.all_pessoajuridicatipos = this.all_pessoajuridicatipos.filter(
            filtra_pessoajuridicatipo
          );
          this.cliente.pessoajuridicatipo.id = null;
        } else {
          //filtro baseado em campo input
          var tipopessoa_at = this.cliente.tipopessoa;
          function filtra_pessoajuridicatipo(value) {
            if (value.id == tipopessoa_at) {
              return value;
            }
          }
          this.all_pessoajuridicatipos = this.all_pessoajuridicatipos.filter(
            filtra_pessoajuridicatipo
          );
          this.cliente.pessoajuridicatipo.id = null;
        }

        if (
          this.all_pessoajuridicatipos &&
          this.all_pessoajuridicatipos.length
        ) {
          // this.cliente.pessoajuridicatipo.id = this.all_pessoajuridicatipos[0].id;
          if (idn !== null) {
            this.cliente.pessoajuridicatipo.id = idn;
            this.$refs.pessoajuridicatipoInput.$emit(
              "change",
              this.cliente.pessoajuridicatipo.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getEstadocivil(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.estadocivils_filter_itens
              ? this.estadocivils_filter_itens
              : {}),
          },
        };
        //if(this.all_estadocivils || Object.keys(this.all_estadocivils).lenght == undefined){
        await this.$store.dispatch("estadocivils/list", params);
        this.all_estadocivils = await this.$store.getters[
          "estadocivils/dropdown"
        ];
        //}

        if (this.all_estadocivils && this.all_estadocivils.length) {
          // this.cliente.estadocivil.id = this.all_estadocivils[0].id;
          if (idn !== null) {
            this.cliente.estadocivil.id = idn;
            this.$refs.estadocivilInput.$emit(
              "change",
              this.cliente.estadocivil.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getPessoasexo(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.pessoasexos_filter_itens
              ? this.pessoasexos_filter_itens
              : {}),
          },
        };
        //if(this.all_pessoasexos || Object.keys(this.all_pessoasexos).lenght == undefined){
        await this.$store.dispatch("pessoasexos/list", params);
        this.all_pessoasexos = await this.$store.getters[
          "pessoasexos/dropdown"
        ];
        //}

        if (this.all_pessoasexos && this.all_pessoasexos.length) {
          // this.cliente.pessoasexo.id = this.all_pessoasexos[0].id;
          if (idn !== null) {
            this.cliente.pessoasexo.id = idn;
            this.$refs.pessoasexoInput.$emit(
              "change",
              this.cliente.pessoasexo.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getTipotelefone(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.tipotelefones_filter_itens
              ? this.tipotelefones_filter_itens
              : {}),
          },
        };
        //if(this.all_tipotelefones || Object.keys(this.all_tipotelefones).lenght == undefined){
        await this.$store.dispatch("tipotelefones/list", params);
        this.all_tipotelefones = await this.$store.getters[
          "tipotelefones/dropdown"
        ];
        //}

        if (this.all_tipotelefones && this.all_tipotelefones.length) {
          // this.cliente.tipotelefone.id = this.all_tipotelefones[0].id;
          if (idn !== null) {
            this.cliente.tipotelefone.id = idn;
            this.$refs.tipotelefoneInput.$emit(
              "change",
              this.cliente.tipotelefone.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getLogradourotipo(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.logradourotipos_filter_itens
              ? this.logradourotipos_filter_itens
              : {}),
          },
        };
        //if(this.all_logradourotipos || Object.keys(this.all_logradourotipos).lenght == undefined){
        await this.$store.dispatch("logradourotipos/list", params);
        this.all_logradourotipos = await this.$store.getters[
          "logradourotipos/dropdown"
        ];
        //}

        if (this.all_logradourotipos && this.all_logradourotipos.length) {
          // this.cliente.logradourotipo.id = this.all_logradourotipos[0].id;
          if (idn !== null) {
            this.cliente.logradourotipo.id = idn;
            this.$refs.logradourotipoInput.$emit(
              "change",
              this.cliente.logradourotipo.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getMunicipio(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.municipios_filter_itens
              ? this.municipios_filter_itens
              : {}),
          },
        };
        //if(this.all_municipios || Object.keys(this.all_municipios).lenght == undefined){
        await this.$store.dispatch("municipios/list", params);
        this.all_municipios = await this.$store.getters["municipios/dropdown"];
        //}

        if (this.all_municipios && this.all_municipios.length) {
          // this.cliente.municipio.id = this.all_municipios[0].id;
          if (idn !== null) {
            this.cliente.municipio.id = idn;
            this.$refs.municipioInput.$emit(
              "change",
              this.cliente.municipio.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    changetipotel() {
      if (this.cliente.tipotelefone.id == 4) {
        this.cliente.telefone = "";
      }
    },
    mask() {
      if (this.cliente.cpfcnpj.length <= 14) {
        this.cliente.tipopessoa.id = "1";
        return "###.###.###-##[#?]"; // Máscara para CPF
      } else {
        this.cliente.tipopessoa.id = "2";
        return "##.###.###/####-##"; // Máscara para CNPJ
      }
    },
    focuscpfcnpjInput() {
      this.$refs.cpfcnpjInput.focus();
    },
    masktel() {
      if (this.cliente.tipotelefone.id == 3) {
        return "(##) # ####-####"; // Máscara para celular
      } else if (
        this.cliente.tipotelefone.id == 2 ||
        this.cliente.tipotelefone.id == 1
      ) {
        return "(##) ####-####"; // Máscara para telefone
      }
      return ""; // Sem máscara padrão
    },

    async buscarcep() {
      try {
        const b_cep = await axios.get(
          `https://viacep.com.br/ws/${this.cliente.cep.replace(
            /\D/g,
            ""
          )}/json/`
        );

        //preenche variaveis
        this.cliente.logradouro = b_cep.data.logradouro;
        this.cliente.bairro = b_cep.data.bairro;
        this.cliente.municipio.id = b_cep.data.ibge;
        this.cliente.logradourotipo.id = null;
        //seleciona tipo de logradouro

        var last_qtd = 0;
        var logra_prov = "###" + b_cep.data.logradouro;
        var logra_new = logra_prov;

        for (const [key, logradourotipo_value] of Object.entries(
          this.all_logradourotipos
        )) {
          if (
            logra_prov
              .toUpperCase()
              .indexOf("###" + logradourotipo_value.name.toUpperCase()) === 0
          ) {
            if (logradourotipo_value.name.length > last_qtd) {
              last_qtd = logradourotipo_value.name.length;
              this.cliente.logradourotipo.id = logradourotipo_value.id;

              //elemina tipo de logradouro do logradouro
              var logra_new = logra_prov;
              logra_new = logra_new.replace(
                new RegExp("###" + logradourotipo_value.name, "gi"),
                ""
              );
              logra_new = logra_new.trim();
            }
          }
        }

        logra_new = logra_new.replace("###", "");
        this.cliente.logradouro = logra_new;
        this.apiValidationErrors.cep = null;
      } catch (error) {
        this.apiValidationErrors.cep = ["CEP não encontrado"];
        this.cliente.logradouro = "";
        this.cliente.bairro = "";
        this.cliente.municipio.id = null;
        this.cliente.logradourotipo.id = null;
        //console.error('Erro ao buscar endereço:', error);
      }
    },

    async selecionapessoajuridicatipo() {
      await this.getPessoajuridicatipo();
      if (this.cliente.tipopessoa.id == 1) {
        this.cliente.pessoajuridicatipo.id = "4";
      }
    },
    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
      // Verifica se o valor termina com um ponto
      let sulfix = "";
      if (valor.toString().endsWith(".")) {
        sulfix = ",";
      }

      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split(".")[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais,
      });
      return valorFormatado.toString() + sulfix;
    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
      let valorFormatado = valorBrasileiro
        .replace(/\./g, "")
        .replace(/,/g, ".");
      // Verifica se o último caractere é uma vírgula
      return valorFormatado;
    },

    goBack() {
      this.$router.push({ name: "List Clientes" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }

      //this.cliente.excerpt = this.cliente.description;
      /**
      this.cliente.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.cliente.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar = Object.assign({}, this.cliente);

        if (dados_enviar.pessoavinculo?.id == null) {
          delete dados_enviar.pessoavinculo;
        }
        if (dados_enviar.tipopessoa?.id == null) {
          delete dados_enviar.tipopessoa;
        }
        if (dados_enviar.pessoajuridicatipo?.id == null) {
          delete dados_enviar.pessoajuridicatipo;
        }
        if (dados_enviar.estadocivil?.id == null) {
          delete dados_enviar.estadocivil;
        }
        if (dados_enviar.pessoasexo?.id == null) {
          delete dados_enviar.pessoasexo;
        }
        if (dados_enviar.tipotelefone?.id == null) {
          delete dados_enviar.tipotelefone;
        }
        if (dados_enviar.logradourotipo?.id == null) {
          delete dados_enviar.logradourotipo;
        }
        if (dados_enviar.municipio?.id == null) {
          delete dados_enviar.municipio;
        }

        if (dados_enviar.cpfcnpj) {
          dados_enviar.cpfcnpj = dados_enviar.cpfcnpj.replace(/\D/g, "");
        }
        if (dados_enviar.nome) {
          dados_enviar.nome = dados_enviar.nome.toUpperCase();
        }
        if (dados_enviar.telefone) {
          dados_enviar.telefone = dados_enviar.telefone.replace(/\D/g, "");
        }
        if (dados_enviar.cep) {
          dados_enviar.cep = dados_enviar.cep.replace(/\D/g, "");
        }

        if (dados_enviar.created_at) {
          delete dados_enviar.created_at;
        }
        if (dados_enviar.updated_at) {
          delete dados_enviar.updated_at;
        }
        if (dados_enviar.deleted_at) {
          delete dados_enviar.deleted_at;
        }
        if (dados_enviar.tenants) {
          delete dados_enviar.tenants;
        }

        if (!this.$route.params.id) {
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("clientes/add", dados_enviar);
          this.cliente = await this.$store.getters["clientes/cliente"];
        } else {
          //atualizar registro
          await this.$store.dispatch("clientes/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("clientes/upload", {
            cliente: this.cliente,
            image: this.file,
          });

          this.cliente.image = await this.$store.getters["clientes/url"];

          await this.$store.dispatch("clientes/update", this.cliente);
          this.cliente = await this.$store.getters["clientes/Cliente"];
        }

        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Clientes Adicionado com sucesso.",
        });

        if (!this.isInsideModal) {
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        } else {
          //fecha modal
          this.$emit("close-modal");
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if (this.insideModalOnSave !== null) {
            this.$emit("callFunction", {
              functionName: this.insideModalOnSave,
              args: [this.cliente.id],
            });
          }
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.cliente.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch("clientes/destroy", this.cliente.id);
        }

        this.cliente.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },

  mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll("span");

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute("tabindex");
    });

    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
