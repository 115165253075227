var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"true":"","link":{
            name: 'Dashboard',
            icon: 'fas fa-chart-line',
            path: '/dashboard',
          }}}),(
            _vm.permissions.includes('view imovels') || 
            _vm.permissions.includes('view contratos') || 
            _vm.permissions.includes('view clientes') 
          )?_c('sidebar-item',{attrs:{"opened":"","true":"","link":{
            name: 'Cadastro',
            icon: 'fas fa-table-list',
          }}},[(_vm.permissions.includes('view clientes'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Clientes',
              path: '/app/Cliente-management/list-Cliente',
            }}}):_vm._e(),(_vm.permissions.includes('view imovels'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Imóveis',
              path: '/app/Imovel-management/list-Imovel',
            }}}):_vm._e(),(_vm.permissions.includes('view contratos'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Locações',
              path: '/app/Contrato-management/list-Contrato',
            }}}):_vm._e()],1):_vm._e(),(
            _vm.permissions.includes('view seguroincendios')
          )?_c('sidebar-item',{attrs:{"opened":"","true":"","link":{
            name: 'Seguro Incêndio',
            icon: 'fas fa-house-fire',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Orçamentos',
              path: '/app/Seguroincendio-management/list-Seguroincendio',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Apólices',
              path: '/app/Seguroincendio-management/list-Seguroincendio-apolice',
            }}})],1):_vm._e(),(
            _vm.permissions.includes('view faturas') 
            || _vm.permissions.includes('view faturaextratos') 
          )?_c('sidebar-item',{attrs:{"true":"","link":{
            name: 'Financeiro',
            icon: 'fas fa-credit-card',
          }}},[(_vm.permissions.includes('view faturas'))?_c('sidebar-item',{attrs:{"link":{
                name: 'Fatura',
                path: '/app/Fatura-management/list-Fatura',
              }}}):_vm._e(),(_vm.permissions.includes('view faturaextratos'))?_c('sidebar-item',{attrs:{"link":{
                name: 'Fatura Extrato',
                path: '/app/Faturaextrato-management/list-Faturaextrato',
              }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Configurações',
            icon: 'fas fa-sliders',
          }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Meu perfil', path: '/app/user-profile' }}}),(_vm.permissions.includes('view users'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Cadastro de Usuários',
              path: '/app/user-management/list-users',
            }}}):_vm._e(),(_vm.permissions.includes('view permissions'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Permissões',
              path: '/app/role-management/list-roles',
            }}}):_vm._e(),(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Meu perfil',
              path: '',
            }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
              name: 'Preferências',
              path: '/app/Preferencia-management/edit-Preferencia/0',
            }}})],1),(false)?_c('sidebar-item',{attrs:{"true":"","link":{
            name: 'Labels',
            icon: 'ni ni-shop text-primary',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'API request name',
              path: '/app/Requesttipo-management/list-Requesttipo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Numero de Parcelas',
              path: '/app/Seguroiprevnumeroparcela-management/list-Seguroiprevnumeroparcela',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'API Requests',
              path: '/app/Apirequest-management/list-Apirequest',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Contratar Seguro Incendio',
              path: '/app/Seguroicontratar-management/list-Seguroicontratar',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Cancelar Seguro Incêndio',
              path: '/app/Seguroicancelar-management/list-Seguroicancelar',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Dashboard',
              path: '/app/Dashboard-management/list-Dashboard',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Preferências',
              path: '/app/Preferencia-management/list-Preferencia',
            }}})],1):_vm._e(),(false)?_c('sidebar-item',{attrs:{"true":"","link":{
            name: 'Sistema',
            icon: 'ni ni-shop text-primary',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Status de apolices',
              path: '/app/Apolicestatu-management/list-Apolicestatu',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Seguradoras',
              path: '/app/Seguradora-management/list-Seguradora',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Tipo de Logradouro',
              path: '/app/Logradourotipo-management/list-Logradourotipo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Tipo de contrato',
              path: '/app/Tipocontrato-management/list-Tipocontrato',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Tipo Pessoa',
              path: '/app/Tipopessoa-management/list-Tipopessoa',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Clausula serviços',
              path: '/app/Clausulaservico-management/list-Clausulaservico',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Status do Seguro Incendio',
              path: '/app/Seguroistatu-management/list-Seguroistatu',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Situação Contrato',
              path: '/app/Situcontrato-management/list-Situcontrato',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Lista de Municipios',
              path: '/app/Municipio-management/list-Municipio',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Tipo de telefone',
              path: '/app/Tipotelefone-management/list-Tipotelefone',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Formas de pagamento',
              path: '/app/Seguroiformapagto-management/list-Seguroiformapagto',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Estado Civil',
              path: '/app/Estadocivil-management/list-Estadocivil',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Sexo',
              path: '/app/Pessoasexo-management/list-Pessoasexo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Vinculo Cliente',
              path: '/app/Pessoavinculo-management/list-Pessoavinculo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Tipo de Empresa',
              path: '/app/Pessoajuridicatipo-management/list-Pessoajuridicatipo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'tipo de Imóvel',
              path: '/app/Imoveltipo-management/list-Imoveltipo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Método de calculo',
              path: '/app/Metodocalculo-management/list-Metodocalculo',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Opções de parcelamento cotado',
              path: '/app/Seguroiparcelamento-management/list-Seguroiparcelamento',
            }}})],1):_vm._e(),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }