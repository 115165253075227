<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Orçamentos</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addSeguroincendio()"
              >
                <span class="btn-inner--icon"
                  ><i class="fas fa-user-edit"></i
                ></span>
                <span class="btn-inner--text">Novo Orçamento</span>
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Pesquisar..."
                clearable
              />
            </div>
          </div>

          <!--
            <ul class="nav nav-tabs nav-fill">    
              <li class="nav-item">
                <a v-bind:class="[navitem_at == 0 ? 'active' : '', 'nav-link']" href="#" @click="navitem_at=0;getList()">Todos</a>
              </li>    
              <li class="nav-item">
                <a v-bind:class="[navitem_at == 1 ? 'active' : '', 'nav-link']" href="#" @click="navitem_at=1;getList()">Orçamento</a>
              </li>    
              <li class="nav-item">
                <a v-bind:class="[navitem_at == 2 ? 'active' : '', 'nav-link']" href="#" @click="navitem_at=2;getList()">Contratados</a>
              </li></ul>
-->
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="seguroincendios"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Locação"
              min-width="50px"
              prop="contrato.codigo"
            />
            <el-table-column label="Detalhes" min-width="150px">
              <template slot-scope="scope">
                <div>
                  <div>
                    <strong> {{ scope.row.enderecotxt }}</strong>
                  </div>
                  <div>
                    <small><strong>Proponente:</strong></small>
                    {{ scope.row.proponentenome }}
                  </div>
                  <div>
                    <small><strong>Beneficiário:</strong></small>
                    {{ scope.row.beneficiarionomes }}
                  </div>
                  <div v-if="scope.row.numeroapolice > 0">
                    <small
                      ><strong>Apólice: </strong
                      >{{ scope.row.numeroapolice }}</small
                    >
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="Status"
              min-width="60px"
              prop="seguroistatu.status"
            >
              <template v-slot="{ row }">
                <div v-if="!['2','17'].includes(row.seguroistatu.id)">
                  {{ row.seguroistatu.status }}
                </div>
                <div class="ml-0" v-if="['2'].includes(row.seguroistatu.id) && !is_vencido(row.validade) && !is_vencido(row.datainicio)">
                  <base-button
                    class="ml-0"
                    type="warning"
                    size="sm"
                    @click="contratarSeguroincendio(row)"
                  >
                    <i class="fas fa-handshake"></i>Clique para Contratar
                  </base-button>
                </div>
                <div class="ml-0" v-if="['2','17'].includes(row.seguroistatu.id) && is_vencido(row.datainicio)">
                  <base-button
                    class="ml-0"
                    type="warning"
                    size="sm"
                    @click="AlterarDataInicio(row)"
                  >
                    <i class="fas fa-calendar"></i>Alterar Inicio
                  </base-button>
                </div>
                
                <div class="ml-0" v-if="['2','17'].includes(row.seguroistatu.id) && is_vencido(row.validade) && !is_vencido(row.datainicio)">
                  <base-button
                    class="ml-0"
                    type="warning"
                    size="sm"
                    @click="Atualizarorcamento(row)"
                  >
                    <i class="fas fa-house-fire"></i>Atualizar Orçamento
                  </base-button>
                </div>
              </template>
              
            </el-table-column>

            <el-table-column min-width="50px" align="right">
              <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                        <a class="dropdown-item" style="cursor:pointer" @click="baixarArquivoProposta(row)" 
                          v-if="row.numeroproposta != 0 && row.propostapdf == 1">
                          <i class="far fa-file-pdf"></i>
                          Baixar Proposta </a
                        >                        
                        <a class="dropdown-item" style="cursor:pointer" @click="baixarArquivo(row)" 
                          v-if="row.numeroapolice != 0 && row.apolicepdf == 1">
                          <i class="fas fa-file-pdf"></i>
                          Baixar Apólice</a
                        >
                        <a class="dropdown-item" style="cursor:pointer" @click="contratarSeguroincendio(row)" 
                          v-if="row.seguroistatu.id == 2 && !is_vencido(row.validade) && !is_vencido(row.datainicio)">
                          <i class="fas fa-handshake"></i>
                          Contratar Seguro</a
                        >
                        <a class="dropdown-item" style="cursor:pointer" @click="AlterarDataInicio(row)" 
                          v-if="['2','17'].includes(row.seguroistatu.id) && is_vencido(row.datainicio)">
                          <i class="fas fa-calendar"></i>
                          Alterar Inicio</a
                        >
                        <a class="dropdown-item" style="cursor:pointer" @click="Atualizarorcamento(row)" 
                          v-if="['2','17'].includes(row.seguroistatu.id) && is_vencido(row.validade) && !is_vencido(row.datainicio)">
                          <i class="fas fa-house-fire"></i>
                          Atualizar Orçamento</a
                        >
                        <a class="dropdown-item" style="cursor:pointer" @click="deleteSeguroincendio(row)" 
                          v-if="row.numeroapolice != 0">
                          <i class="fas fa-ban"></i>
                          Cancelar Apólice </a
                        >
                    </el-dropdown-menu>
                  </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ total ? from + 1 : 0 }} até {{ to }} de um total de
              {{ total }} registros.

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import flatPicker from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      contrato_filter: null,
      seguroincendios: [],
      navitem_at: 0,
      navitem_filtros: [{}, { contratado: 0 }, { contratado: 1 }],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    
    formatDate(dateStr) {
      const date = new Date(dateStr + 'T00:00');
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("pt-BR", options);
    },
    
    is_vencido(validade){
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      validade = new Date(validade + 'T00:00');
      return validade < hoje;
    },

    async getList() {
      try {
        if (this.$route.params.contrato_filter) {
          this.pagination.perPage = 50;
          this.contrato_filter=this.$route.params.contrato_filter;
        }

        //limpa contrato_filter se pesquisar
        if(this.query){
          this.contrato_filter=null;
        }

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.contrato_filter
              ? { contrato_id: this.contrato_filter }
              : {}),
            ...{ isnot_seguroistatu_id: 4 },
            ...(this.navitem_filtros[this.navitem_at]
              ? this.navitem_filtros[this.navitem_at]
              : {}),
            ...(this.query ? { proponentenome: this.query } : {}),
            ...(this.query ? { "contrato.codigo": this.query } : {}),
            ...(this.query ? { beneficiarionomes: this.query } : {}),
            ...(this.query ? { enderecotxt: this.query } : {}),
            ...(this.query ? { numeroapolice: this.query } : {}),
            ...(this.query ? { numeroproposta: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "contrato,seguroistatu,clausulaservico,metodocalculo",
        };

        if (this.$route.params.contrato_filter) {
          //reseeta filtro para nao influenciar na proxima consulta
          this.$route.params.contrato_filter = null;
        }

        await this.$store.dispatch("seguroincendios/list", params).then(() => {
          this.seguroincendios = this.$store.getters["seguroincendios/list"];
          this.total = this.$store.getters["seguroincendios/listTotal"];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async baixarArquivoProposta(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch(
          "seguroincendios/baixarArquivo",
          ["proposta", row.numeroproposta]
        );
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: "application/pdf" });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, "_blank");
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
    async baixarArquivo(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch(
          "seguroincendios/baixarArquivo",
          ["apolice", row.numeroapolice]
        );
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: "application/pdf" });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, "_blank");
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
    contratarSeguroincendio(row) {
      this.$router.push({
        name: "Add Seguroicontratar",
        params: { seguroincendio_id: row.id },
      });
    },
    async deleteSeguroincendio(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message:
            "Você não esta habilitado para alterar dados de seguroincendios.",
        });
        return;
      }
      try {
        const confirmation = await swal.fire({
          title: `Cancelar esta apólice?`,
          type: "question",
          input: "text",
          inputPlaceholder: "Digite o Motivo do cancelamento",
          inputAttributes: {
            maxlength: "100",
            autocapitalize: "off",
          },
          inputValidator: (value) => {
            if (!value || value.length < 5) {
              return "Você precisa digitar um motivo!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Sim, Cancelar!",
          cancelButtonText: "Não, Manter Apólice",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (confirmation.isConfirmed === true) {
          var seguroicancelar = {
            type: "seguroicancelars",
            motivo: confirmation.value,
            seguroincendio: {
              type: "seguroincendios",
              id: row.id,
            },
            relationshipNames: ["seguroincendio"],
          };

          await this.$store.dispatch("seguroicancelars/add", seguroicancelar);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Apólice Enviada para Cancelamento.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async AlterarDataInicio(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message:
            "Você não esta habilitado para alterar a data",
        });
        return;
      }
      try {
        const { value: date } = await swal.fire({
          title: `Data de inicio de vigencia do seguro`,
          input: "date",
          inputValidator: (value) => {
            if (!value) {
              return "Você precisa selecionar uma data!";
            }

            //verifica se é data futura
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            var inicio = new Date(value + 'T00:00');
            if (inicio < hoje) {
              return "A data deve ser maior ou igual a hoje!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Salvar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (date) {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: date,
            contrato: row.contrato,
            clausulaservico: row.clausulaservico,
            metodocalculo: row.metodocalculo,
            relationshipNames: [
              "contrato",
              "clausulaservico",
              "metodocalculo"
            ],
          };
          

          await this.$store.dispatch("seguroincendios/updatedata", seguroincendio);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para nova cotação.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async Atualizarorcamento(row){
      try {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: null,
            contrato: row.contrato,
            clausulaservico: row.clausulaservico,
            metodocalculo: row.metodocalculo,
            relationshipNames: [
              "contrato",
              "clausulaservico",
              "metodocalculo"
            ],
          };
          
          await this.$store.dispatch("seguroincendios/updatedata", seguroincendio);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para atualizar cotação.",
          });
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    addSeguroincendio() {
      this.$router.push({ name: "Add Seguroincendio" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
