var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-nav',{staticClass:"navbar-top border-bottom navbar-expand",class:{ 'bg-primary navbar-dark': _vm.type === 'default' },attrs:{"container-classes":"container-fluid"}},[_c('form',{staticClass:"navbar-search form-inline mr-sm-3",class:{
      'navbar-search-light': _vm.type === 'default',
      'navbar-search-dark': _vm.type === 'light',
    },attrs:{"id":"navbar-search-main"}},[_c('div',{staticClass:"form-group mb-0"},[_c('div',{staticClass:"input-group input-group-alternative input-group-merge"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fas fa-search"})])]),_c('input',{staticClass:"form-control",attrs:{"placeholder":"Buscar","type":"text"}})])]),_c('button',{staticClass:"close",attrs:{"type":"button","data-action":"search-close","data-target":"#navbar-search-main","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('ul',{staticClass:"navbar-nav align-items-center ml-md-auto"},[_c('li',{staticClass:"nav-item d-xl-none"},[_c('div',{staticClass:"pr-3 sidenav-toggler",class:{
          active: _vm.$sidebar.showSidebar,
          'sidenav-toggler-dark': _vm.type === 'default',
          'sidenav-toggler-light': _vm.type === 'light',
        },on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"sidenav-toggler-inner"},[_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"}),_c('i',{staticClass:"sidenav-toggler-line"})])])]),_c('li',{staticClass:"nav-item d-sm-none"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#","data-action":"search-show","data-target":"#navbar-search-main"}},[_c('i',{staticClass:"ni ni-zoom-split-in"})])])]),_c('ul',{staticClass:"navbar-nav align-items-center ml-auto ml-md-0"},[_c('base-dropdown',{staticClass:"nav-item",attrs:{"menu-on-right":"","tag":"li","title-tag":"a","title-classes":"nav-link pr-0"}},[_c('a',{staticClass:"nav-link pr-0",attrs:{"slot":"title-container","href":"#"},on:{"click":function($event){$event.preventDefault();}},slot:"title-container"},[_c('div',{staticClass:"media align-items-center"},[_c('span',{staticClass:"avatar avatar-sm rounded-circle avatar-image",style:({
              'background-image': `url('${_vm.profileImage}')`,
            })}),_c('div',{staticClass:"media-body ml-2 d-none d-lg-block"},[_c('span',{staticClass:"mb-0 text-sm font-weight-bold"},[_vm._v(_vm._s(_vm.title))])])])]),[_c('div',{staticClass:"dropdown-header noti-title"},[_c('h6',{staticClass:"text-overflow m-0"},[_vm._v("Welcome!")])]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"/examples/user-profile"}},[_c('i',{staticClass:"ni ni-single-02"}),_c('span',[_vm._v("Meu perfil")])]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item",attrs:{"to":""},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_c('i',{staticClass:"ni ni-user-run"}),_c('span',[_vm._v("Logout")])])]],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }