import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

// Example pages
import UserProfile from "@/views/app/UserProfile.vue";
import ListRolePage from "@/views/app/RoleManagement/ListRolePage.vue";
import EditRolePage from "@/views/app/RoleManagement/EditRolePage.vue";
import AddRolePage from "@/views/app/RoleManagement/AddRolePage.vue";
import ListUserPage from "@/views/app/UserManagement/ListUserPage.vue";
import EditUserPage from "@/views/app/UserManagement/EditUserPage.vue";
import AddUserPage from "@/views/app/UserManagement/AddUserPage.vue";
import ListCategoryPage from "@/views/Examples/CategoryManagement/ListCategoryPage.vue";
import EditCategoryPage from "@/views/Examples/CategoryManagement/EditCategoryPage.vue";
import AddCategoryPage from "@/views/Examples/CategoryManagement/AddCategoryPage.vue";
// Tag Management
import ListTagPage from "@/views/Examples/TagManagement/ListTagPage.vue";
import EditTagPage from "@/views/Examples/TagManagement/EditTagPage";
import AddTagPage from "@/views/Examples/TagManagement/AddTagPage.vue";
// Item Management
import ListItemPage from "@/views/Examples/ItemManagement/ListItemPage.vue";
import AddItemPage from "@/views/Examples/ItemManagement/AddItemPage.vue";
import EditItemPage from "@/views/Examples/ItemManagement/EditItemPage";
// Tenant Management
        import ListTenantPage from "@/views/app/TenantManagement/ListTenantPage.vue";
        import AddTenantPage from "@/views/app/TenantManagement/AddTenantPage.vue";
        import EditTenantPage from "@/views/app/TenantManagement/EditTenantPage.vue";
// Apolicestatu Management
        import ListApolicestatuPage from "@/views/app/ApolicestatuManagement/ListApolicestatuPage.vue";
        import AddApolicestatuPage from "@/views/app/ApolicestatuManagement/AddApolicestatuPage.vue";
        import EditApolicestatuPage from "@/views/app/ApolicestatuManagement/EditApolicestatuPage.vue";
// Seguradora Management
        import ListSeguradoraPage from "@/views/app/SeguradoraManagement/ListSeguradoraPage.vue";
        import AddSeguradoraPage from "@/views/app/SeguradoraManagement/AddSeguradoraPage.vue";
        import EditSeguradoraPage from "@/views/app/SeguradoraManagement/EditSeguradoraPage.vue";
// Logradourotipo Management
        import ListLogradourotipoPage from "@/views/app/LogradourotipoManagement/ListLogradourotipoPage.vue";
        import AddLogradourotipoPage from "@/views/app/LogradourotipoManagement/AddLogradourotipoPage.vue";
        import EditLogradourotipoPage from "@/views/app/LogradourotipoManagement/EditLogradourotipoPage.vue";
// Tipocontrato Management
        import ListTipocontratoPage from "@/views/app/TipocontratoManagement/ListTipocontratoPage.vue";
        import AddTipocontratoPage from "@/views/app/TipocontratoManagement/AddTipocontratoPage.vue";
        import EditTipocontratoPage from "@/views/app/TipocontratoManagement/EditTipocontratoPage.vue";
// Tipopessoa Management
        import ListTipopessoaPage from "@/views/app/TipopessoaManagement/ListTipopessoaPage.vue";
        import AddTipopessoaPage from "@/views/app/TipopessoaManagement/AddTipopessoaPage.vue";
        import EditTipopessoaPage from "@/views/app/TipopessoaManagement/EditTipopessoaPage.vue";
// Clausulaservico Management
        import ListClausulaservicoPage from "@/views/app/ClausulaservicoManagement/ListClausulaservicoPage.vue";
        import AddClausulaservicoPage from "@/views/app/ClausulaservicoManagement/AddClausulaservicoPage.vue";
        import EditClausulaservicoPage from "@/views/app/ClausulaservicoManagement/EditClausulaservicoPage.vue";
// Seguroistatu Management
        import ListSeguroistatuPage from "@/views/app/SeguroistatuManagement/ListSeguroistatuPage.vue";
        import AddSeguroistatuPage from "@/views/app/SeguroistatuManagement/AddSeguroistatuPage.vue";
        import EditSeguroistatuPage from "@/views/app/SeguroistatuManagement/EditSeguroistatuPage.vue";
// Situcontrato Management
        import ListSitucontratoPage from "@/views/app/SitucontratoManagement/ListSitucontratoPage.vue";
        import AddSitucontratoPage from "@/views/app/SitucontratoManagement/AddSitucontratoPage.vue";
        import EditSitucontratoPage from "@/views/app/SitucontratoManagement/EditSitucontratoPage.vue";
// Municipio Management
        import ListMunicipioPage from "@/views/app/MunicipioManagement/ListMunicipioPage.vue";
        import AddMunicipioPage from "@/views/app/MunicipioManagement/AddMunicipioPage.vue";
        import EditMunicipioPage from "@/views/app/MunicipioManagement/EditMunicipioPage.vue";
// Tipotelefone Management
        import ListTipotelefonePage from "@/views/app/TipotelefoneManagement/ListTipotelefonePage.vue";
        import AddTipotelefonePage from "@/views/app/TipotelefoneManagement/AddTipotelefonePage.vue";
        import EditTipotelefonePage from "@/views/app/TipotelefoneManagement/EditTipotelefonePage.vue";
// Requesttipo Management
        import ListRequesttipoPage from "@/views/app/RequesttipoManagement/ListRequesttipoPage.vue";
        import AddRequesttipoPage from "@/views/app/RequesttipoManagement/AddRequesttipoPage.vue";
        import EditRequesttipoPage from "@/views/app/RequesttipoManagement/EditRequesttipoPage.vue";
// Seguroiformapagto Management
        import ListSeguroiformapagtoPage from "@/views/app/SeguroiformapagtoManagement/ListSeguroiformapagtoPage.vue";
        import AddSeguroiformapagtoPage from "@/views/app/SeguroiformapagtoManagement/AddSeguroiformapagtoPage.vue";
        import EditSeguroiformapagtoPage from "@/views/app/SeguroiformapagtoManagement/EditSeguroiformapagtoPage.vue";
// Estadocivil Management
        import ListEstadocivilPage from "@/views/app/EstadocivilManagement/ListEstadocivilPage.vue";
        import AddEstadocivilPage from "@/views/app/EstadocivilManagement/AddEstadocivilPage.vue";
        import EditEstadocivilPage from "@/views/app/EstadocivilManagement/EditEstadocivilPage.vue";
// Pessoasexo Management
        import ListPessoasexoPage from "@/views/app/PessoasexoManagement/ListPessoasexoPage.vue";
        import AddPessoasexoPage from "@/views/app/PessoasexoManagement/AddPessoasexoPage.vue";
        import EditPessoasexoPage from "@/views/app/PessoasexoManagement/EditPessoasexoPage.vue";
// Pessoavinculo Management
        import ListPessoavinculoPage from "@/views/app/PessoavinculoManagement/ListPessoavinculoPage.vue";
        import AddPessoavinculoPage from "@/views/app/PessoavinculoManagement/AddPessoavinculoPage.vue";
        import EditPessoavinculoPage from "@/views/app/PessoavinculoManagement/EditPessoavinculoPage.vue";
// Pessoajuridicatipo Management
        import ListPessoajuridicatipoPage from "@/views/app/PessoajuridicatipoManagement/ListPessoajuridicatipoPage.vue";
        import AddPessoajuridicatipoPage from "@/views/app/PessoajuridicatipoManagement/AddPessoajuridicatipoPage.vue";
        import EditPessoajuridicatipoPage from "@/views/app/PessoajuridicatipoManagement/EditPessoajuridicatipoPage.vue";
// Seguroiprevnumeroparcela Management
        import ListSeguroiprevnumeroparcelaPage from "@/views/app/SeguroiprevnumeroparcelaManagement/ListSeguroiprevnumeroparcelaPage.vue";
        import AddSeguroiprevnumeroparcelaPage from "@/views/app/SeguroiprevnumeroparcelaManagement/AddSeguroiprevnumeroparcelaPage.vue";
        import EditSeguroiprevnumeroparcelaPage from "@/views/app/SeguroiprevnumeroparcelaManagement/EditSeguroiprevnumeroparcelaPage.vue";
// Imoveltipo Management
        import ListImoveltipoPage from "@/views/app/ImoveltipoManagement/ListImoveltipoPage.vue";
        import AddImoveltipoPage from "@/views/app/ImoveltipoManagement/AddImoveltipoPage.vue";
        import EditImoveltipoPage from "@/views/app/ImoveltipoManagement/EditImoveltipoPage.vue";
// Metodocalculo Management
        import ListMetodocalculoPage from "@/views/app/MetodocalculoManagement/ListMetodocalculoPage.vue";
        import AddMetodocalculoPage from "@/views/app/MetodocalculoManagement/AddMetodocalculoPage.vue";
        import EditMetodocalculoPage from "@/views/app/MetodocalculoManagement/EditMetodocalculoPage.vue";
// Cliente Management
        import ListClientePage from "@/views/app/ClienteManagement/ListClientePage.vue";
        import AddClientePage from "@/views/app/ClienteManagement/AddClientePage.vue";
        import EditClientePage from "@/views/app/ClienteManagement/EditClientePage.vue";
// Imovel Management
        import ListImovelPage from "@/views/app/ImovelManagement/ListImovelPage.vue";
        import AddImovelPage from "@/views/app/ImovelManagement/AddImovelPage.vue";
        import EditImovelPage from "@/views/app/ImovelManagement/EditImovelPage.vue";
// Seguroiparcelamento Management
        import ListSeguroiparcelamentoPage from "@/views/app/SeguroiparcelamentoManagement/ListSeguroiparcelamentoPage.vue";
        import AddSeguroiparcelamentoPage from "@/views/app/SeguroiparcelamentoManagement/AddSeguroiparcelamentoPage.vue";
        import EditSeguroiparcelamentoPage from "@/views/app/SeguroiparcelamentoManagement/EditSeguroiparcelamentoPage.vue";
// Contrato Management
        import ListContratoPage from "@/views/app/ContratoManagement/ListContratoPage.vue";
        import AddContratoPage from "@/views/app/ContratoManagement/AddContratoPage.vue";
        import EditContratoPage from "@/views/app/ContratoManagement/EditContratoPage.vue";
// Seguroincendio Management
        import ListSeguroincendioPage from "@/views/app/SeguroincendioManagement/ListSeguroincendioPage.vue";
        import ListSeguroincendioApolicePage from "@/views/app/SeguroincendioManagement/ListSeguroincendioApolicePage.vue";
        import AddSeguroincendioPage from "@/views/app/SeguroincendioManagement/AddSeguroincendioPage.vue";
        import EditSeguroincendioPage from "@/views/app/SeguroincendioManagement/EditSeguroincendioPage.vue";
// Apirequest Management
        import ListApirequestPage from "@/views/app/ApirequestManagement/ListApirequestPage.vue";
        import AddApirequestPage from "@/views/app/ApirequestManagement/AddApirequestPage.vue";
        import EditApirequestPage from "@/views/app/ApirequestManagement/EditApirequestPage.vue";
// Seguroicontratar Management
        import ListSeguroicontratarPage from "@/views/app/SeguroicontratarManagement/ListSeguroicontratarPage.vue";
        import AddSeguroicontratarPage from "@/views/app/SeguroicontratarManagement/AddSeguroicontratarPage.vue";
        import EditSeguroicontratarPage from "@/views/app/SeguroicontratarManagement/EditSeguroicontratarPage.vue";
// Seguroicancelar Management
        import ListSeguroicancelarPage from "@/views/app/SeguroicancelarManagement/ListSeguroicancelarPage.vue";
        import AddSeguroicancelarPage from "@/views/app/SeguroicancelarManagement/AddSeguroicancelarPage.vue";
        import EditSeguroicancelarPage from "@/views/app/SeguroicancelarManagement/EditSeguroicancelarPage.vue";
// Fatura Management
        import ListFaturaPage from "@/views/app/FaturaManagement/ListFaturaPage.vue";
        import AddFaturaPage from "@/views/app/FaturaManagement/AddFaturaPage.vue";
        import EditFaturaPage from "@/views/app/FaturaManagement/EditFaturaPage.vue";
// Dashboard Management
        import ListDashboardPage from "@/views/app/DashboardManagement/ListDashboardPage.vue";
        import AddDashboardPage from "@/views/app/DashboardManagement/AddDashboardPage.vue";
        import EditDashboardPage from "@/views/app/DashboardManagement/EditDashboardPage.vue";
// Preferencia Management
        import ListPreferenciaPage from "@/views/app/PreferenciaManagement/ListPreferenciaPage.vue";
        import AddPreferenciaPage from "@/views/app/PreferenciaManagement/AddPreferenciaPage.vue";
        import EditPreferenciaPage from "@/views/app/PreferenciaManagement/EditPreferenciaPage.vue";
// Faturaextrato Management
        import ListFaturaextratoPage from "@/views/app/FaturaextratoManagement/ListFaturaextratoPage.vue";
        import AddFaturaextratoPage from "@/views/app/FaturaextratoManagement/AddFaturaextratoPage.vue";
        import EditFaturaextratoPage from "@/views/app/FaturaextratoManagement/EditFaturaextratoPage.vue";
//App pages end

// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Calendar/Calendar.vue");
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Charts.vue");

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Buttons.vue");
const Cards = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Cards.vue");
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/GridSystem.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Typography.vue"
  ); 
const CountdownTimer = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Components/CountdownTimer.vue");

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue");
const AlternativeDashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Dashboard/AlternativeDashboard.vue"
  );
const Widgets = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Widgets.vue");

// Forms pages
const FormElements = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormElements.vue");
const FormComponents = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormComponents.vue");
const FormValidation = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormValidation.vue");

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/GoogleMaps.vue");
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/VectorMaps.vue");

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ "@/views/app/UserProfile.vue");
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/TimeLinePage.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const Promo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Promo.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");

const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Email.vue");

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/RegularTables.vue");
const SortableTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/SortableTables.vue");
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/PaginatedTables.vue");

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";
import admin_creator from "@/middleware/admin_creator";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
    {
      path: "CountdownTimer",
      name: "CountdownTimer",
      component: CountdownTimer
    }

    
  ]
};
let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "category-management/list-categories",
      name: "List Categories",
      components: { default: ListCategoryPage },
      meta: { middleware: admin_creator }
    },
    {
      path: "category-management/add-category",
      name: "Add Category",
      components: { default: AddCategoryPage },
      meta: { middleware: admin_creator }
    },
    {
      path: "category-management/edit-category/:id",
      name: "Edit Category",
      components: { default: EditCategoryPage },
      meta: { middleware: admin_creator }
    },
    {
      path: "tag-management/list-tags",
      name: "List Tags",
      components: { default: ListTagPage },
      meta: { middleware: admin_creator }
    },
    {
      path: "tag-management/add-tag",
      name: "Add Tag",
      components: { default: AddTagPage },
      meta: { middleware: admin_creator }
    },
    {
      path: "tag-management/edit-tag/:id",
      name: "Edit Tag",
      components: { default: EditTagPage },
      meta: { middleware: admin_creator }
    },
    {
      path: "item-management/list-items",
      name: "List Items",
      components: { default: ListItemPage },
      meta: { middleware: auth }
    },
    {
      path: "item-management/add-item",
      name: "Add Item",
      components: { default: AddItemPage },
      meta: { middleware: auth }
    },
    {
      path: "item-management/edit-item/:id",
      name: "Edit Item",
      components: { default: EditItemPage },
      meta: { middleware: auth }
    }
  ]
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Form elements",
      component: FormElements
    },
    {
      path: "components",
      name: "Form components",
      component: FormComponents
    },
    {
      path: "validation",
      name: "Form validation",
      component: FormValidation
    }
  ]
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/table/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      component: RegularTables
    },
    {
      path: "sortable",
      name: "Sortable",
      component: SortableTables
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "vector",
      name: "Vector Map",
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    },
    {
      path: "/promo",
      name: "Promo",
      component: Promo,
      meta: { middleware: guest }
    },
    {
      path: "/password/reset",
      name: "PasswordReset",
      component: PasswordReset,
      meta: { middleware: guest }
    },
    {
      path: "/password/email",
      name: "PasswordEmail",
      component: PasswordEmail,
      meta: { middleware: guest }
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }
  ]
};

let appMenu = {
  path: "/app",
  component: DashboardLayout,
  name: "App",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth }
    },
    {
      path: "role-management/list-roles",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: { middleware: admin }
    },
    {
      path: "role-management/add-role",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: { middleware: admin }
    },
    {
      path: "role-management/edit-role/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: admin }
    },
    {
      path: "user-management/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: admin }
    },
    {
      path: "user-management/add-user",
      name: "Add User",
      components: { default: AddUserPage },
      meta: { middleware: admin }
    },
    {
      path: "user-management/edit-user/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: admin }
    },
    
        {
          path: "Faturaextrato-management/list-Faturaextrato",
          name: "List Faturaextratos",
          components: { default: ListFaturaextratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Faturaextrato-management/add-Faturaextrato",
          name: "Add Faturaextrato",
          components: { default: AddFaturaextratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Faturaextrato-management/edit-Faturaextrato/:id",
          name: "Edit Faturaextrato",
          components: { default: EditFaturaextratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Preferencia-management/list-Preferencia",
          name: "List Preferencias",
          components: { default: ListPreferenciaPage },
          meta: { middleware: auth }
        },
        {
          path: "Preferencia-management/add-Preferencia",
          name: "Add Preferencia",
          components: { default: AddPreferenciaPage },
          meta: { middleware: auth }
        },
        {
          path: "Preferencia-management/edit-Preferencia/:id",
          name: "Edit Preferencia",
          components: { default: EditPreferenciaPage },
          meta: { middleware: auth }
        },
        {
          path: "Dashboard-management/list-Dashboard",
          name: "List Dashboards",
          components: { default: ListDashboardPage },
          meta: { middleware: auth }
        },
        {
          path: "Dashboard-management/add-Dashboard",
          name: "Add Dashboard",
          components: { default: AddDashboardPage },
          meta: { middleware: auth }
        },
        {
          path: "Dashboard-management/edit-Dashboard/:id",
          name: "Edit Dashboard",
          components: { default: EditDashboardPage },
          meta: { middleware: auth }
        },
        {
          path: "Fatura-management/list-Fatura",
          name: "List Faturas",
          components: { default: ListFaturaPage },
          meta: { middleware: auth }
        },
        {
          path: "Fatura-management/add-Fatura",
          name: "Add Fatura",
          components: { default: AddFaturaPage },
          meta: { middleware: auth }
        },
        {
          path: "Fatura-management/edit-Fatura/:id",
          name: "Edit Fatura",
          components: { default: EditFaturaPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroicancelar-management/list-Seguroicancelar",
          name: "List Seguroicancelars",
          components: { default: ListSeguroicancelarPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroicancelar-management/add-Seguroicancelar",
          name: "Add Seguroicancelar",
          components: { default: AddSeguroicancelarPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroicancelar-management/edit-Seguroicancelar/:id",
          name: "Edit Seguroicancelar",
          components: { default: EditSeguroicancelarPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroicontratar-management/list-Seguroicontratar",
          name: "List Seguroicontratars",
          components: { default: ListSeguroicontratarPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroicontratar-management/add-Seguroicontratar",
          name: "Add Seguroicontratar",
          components: { default: AddSeguroicontratarPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroicontratar-management/edit-Seguroicontratar/:id",
          name: "Edit Seguroicontratar",
          components: { default: EditSeguroicontratarPage },
          meta: { middleware: auth }
        },
        {
          path: "Apirequest-management/list-Apirequest",
          name: "List Apirequests",
          components: { default: ListApirequestPage },
          meta: { middleware: auth }
        },
        {
          path: "Apirequest-management/add-Apirequest",
          name: "Add Apirequest",
          components: { default: AddApirequestPage },
          meta: { middleware: auth }
        },
        {
          path: "Apirequest-management/edit-Apirequest/:id",
          name: "Edit Apirequest",
          components: { default: EditApirequestPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroincendio-management/list-Seguroincendio",
          name: "List Seguroincendios",
          components: { default: ListSeguroincendioPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroincendio-management/list-Seguroincendio-Apolice",
          name: "List Seguroincendioapolices",
          components: { default: ListSeguroincendioApolicePage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroincendio-management/add-Seguroincendio",
          name: "Add Seguroincendio",
          components: { default: AddSeguroincendioPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroincendio-management/edit-Seguroincendio/:id",
          name: "Edit Seguroincendio",
          components: { default: EditSeguroincendioPage },
          meta: { middleware: auth }
        },
        {
          path: "Contrato-management/list-Contrato",
          name: "List Contratos",
          components: { default: ListContratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Contrato-management/add-Contrato",
          name: "Add Contrato",
          components: { default: AddContratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Contrato-management/edit-Contrato/:id",
          name: "Edit Contrato",
          components: { default: EditContratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiparcelamento-management/list-Seguroiparcelamento",
          name: "List Seguroiparcelamentos",
          components: { default: ListSeguroiparcelamentoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiparcelamento-management/add-Seguroiparcelamento",
          name: "Add Seguroiparcelamento",
          components: { default: AddSeguroiparcelamentoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiparcelamento-management/edit-Seguroiparcelamento/:id",
          name: "Edit Seguroiparcelamento",
          components: { default: EditSeguroiparcelamentoPage },
          meta: { middleware: auth }
        },
        {
          path: "Imovel-management/list-Imovel",
          name: "List Imovels",
          components: { default: ListImovelPage },
          meta: { middleware: auth }
        },
        {
          path: "Imovel-management/add-Imovel",
          name: "Add Imovel",
          components: { default: AddImovelPage },
          meta: { middleware: auth }
        },
        {
          path: "Imovel-management/edit-Imovel/:id",
          name: "Edit Imovel",
          components: { default: EditImovelPage },
          meta: { middleware: auth }
        },
        {
          path: "Cliente-management/list-Cliente",
          name: "List Clientes",
          components: { default: ListClientePage },
          meta: { middleware: auth }
        },
        {
          path: "Cliente-management/add-Cliente",
          name: "Add Cliente",
          components: { default: AddClientePage },
          meta: { middleware: auth }
        },
        {
          path: "Cliente-management/edit-Cliente/:id",
          name: "Edit Cliente",
          components: { default: EditClientePage },
          meta: { middleware: auth }
        },
        {
          path: "Metodocalculo-management/list-Metodocalculo",
          name: "List Metodocalculos",
          components: { default: ListMetodocalculoPage },
          meta: { middleware: auth }
        },
        {
          path: "Metodocalculo-management/add-Metodocalculo",
          name: "Add Metodocalculo",
          components: { default: AddMetodocalculoPage },
          meta: { middleware: auth }
        },
        {
          path: "Metodocalculo-management/edit-Metodocalculo/:id",
          name: "Edit Metodocalculo",
          components: { default: EditMetodocalculoPage },
          meta: { middleware: auth }
        },
        {
          path: "Imoveltipo-management/list-Imoveltipo",
          name: "List Imoveltipos",
          components: { default: ListImoveltipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Imoveltipo-management/add-Imoveltipo",
          name: "Add Imoveltipo",
          components: { default: AddImoveltipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Imoveltipo-management/edit-Imoveltipo/:id",
          name: "Edit Imoveltipo",
          components: { default: EditImoveltipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiprevnumeroparcela-management/list-Seguroiprevnumeroparcela",
          name: "List Seguroiprevnumeroparcelas",
          components: { default: ListSeguroiprevnumeroparcelaPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiprevnumeroparcela-management/add-Seguroiprevnumeroparcela",
          name: "Add Seguroiprevnumeroparcela",
          components: { default: AddSeguroiprevnumeroparcelaPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiprevnumeroparcela-management/edit-Seguroiprevnumeroparcela/:id",
          name: "Edit Seguroiprevnumeroparcela",
          components: { default: EditSeguroiprevnumeroparcelaPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoajuridicatipo-management/list-Pessoajuridicatipo",
          name: "List Pessoajuridicatipos",
          components: { default: ListPessoajuridicatipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoajuridicatipo-management/add-Pessoajuridicatipo",
          name: "Add Pessoajuridicatipo",
          components: { default: AddPessoajuridicatipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoajuridicatipo-management/edit-Pessoajuridicatipo/:id",
          name: "Edit Pessoajuridicatipo",
          components: { default: EditPessoajuridicatipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoavinculo-management/list-Pessoavinculo",
          name: "List Pessoavinculos",
          components: { default: ListPessoavinculoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoavinculo-management/add-Pessoavinculo",
          name: "Add Pessoavinculo",
          components: { default: AddPessoavinculoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoavinculo-management/edit-Pessoavinculo/:id",
          name: "Edit Pessoavinculo",
          components: { default: EditPessoavinculoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoasexo-management/list-Pessoasexo",
          name: "List Pessoasexos",
          components: { default: ListPessoasexoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoasexo-management/add-Pessoasexo",
          name: "Add Pessoasexo",
          components: { default: AddPessoasexoPage },
          meta: { middleware: auth }
        },
        {
          path: "Pessoasexo-management/edit-Pessoasexo/:id",
          name: "Edit Pessoasexo",
          components: { default: EditPessoasexoPage },
          meta: { middleware: auth }
        },
        {
          path: "Estadocivil-management/list-Estadocivil",
          name: "List Estadocivils",
          components: { default: ListEstadocivilPage },
          meta: { middleware: auth }
        },
        {
          path: "Estadocivil-management/add-Estadocivil",
          name: "Add Estadocivil",
          components: { default: AddEstadocivilPage },
          meta: { middleware: auth }
        },
        {
          path: "Estadocivil-management/edit-Estadocivil/:id",
          name: "Edit Estadocivil",
          components: { default: EditEstadocivilPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiformapagto-management/list-Seguroiformapagto",
          name: "List Seguroiformapagtos",
          components: { default: ListSeguroiformapagtoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiformapagto-management/add-Seguroiformapagto",
          name: "Add Seguroiformapagto",
          components: { default: AddSeguroiformapagtoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroiformapagto-management/edit-Seguroiformapagto/:id",
          name: "Edit Seguroiformapagto",
          components: { default: EditSeguroiformapagtoPage },
          meta: { middleware: auth }
        },
        {
          path: "Requesttipo-management/list-Requesttipo",
          name: "List Requesttipos",
          components: { default: ListRequesttipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Requesttipo-management/add-Requesttipo",
          name: "Add Requesttipo",
          components: { default: AddRequesttipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Requesttipo-management/edit-Requesttipo/:id",
          name: "Edit Requesttipo",
          components: { default: EditRequesttipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipotelefone-management/list-Tipotelefone",
          name: "List Tipotelefones",
          components: { default: ListTipotelefonePage },
          meta: { middleware: auth }
        },
        {
          path: "Tipotelefone-management/add-Tipotelefone",
          name: "Add Tipotelefone",
          components: { default: AddTipotelefonePage },
          meta: { middleware: auth }
        },
        {
          path: "Tipotelefone-management/edit-Tipotelefone/:id",
          name: "Edit Tipotelefone",
          components: { default: EditTipotelefonePage },
          meta: { middleware: auth }
        },
        {
          path: "Municipio-management/list-Municipio",
          name: "List Municipios",
          components: { default: ListMunicipioPage },
          meta: { middleware: auth }
        },
        {
          path: "Municipio-management/add-Municipio",
          name: "Add Municipio",
          components: { default: AddMunicipioPage },
          meta: { middleware: auth }
        },
        {
          path: "Municipio-management/edit-Municipio/:id",
          name: "Edit Municipio",
          components: { default: EditMunicipioPage },
          meta: { middleware: auth }
        },
        {
          path: "Situcontrato-management/list-Situcontrato",
          name: "List Situcontratos",
          components: { default: ListSitucontratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Situcontrato-management/add-Situcontrato",
          name: "Add Situcontrato",
          components: { default: AddSitucontratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Situcontrato-management/edit-Situcontrato/:id",
          name: "Edit Situcontrato",
          components: { default: EditSitucontratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroistatu-management/list-Seguroistatu",
          name: "List Seguroistatus",
          components: { default: ListSeguroistatuPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroistatu-management/add-Seguroistatu",
          name: "Add Seguroistatu",
          components: { default: AddSeguroistatuPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguroistatu-management/edit-Seguroistatu/:id",
          name: "Edit Seguroistatu",
          components: { default: EditSeguroistatuPage },
          meta: { middleware: auth }
        },
        {
          path: "Clausulaservico-management/list-Clausulaservico",
          name: "List Clausulaservicos",
          components: { default: ListClausulaservicoPage },
          meta: { middleware: auth }
        },
        {
          path: "Clausulaservico-management/add-Clausulaservico",
          name: "Add Clausulaservico",
          components: { default: AddClausulaservicoPage },
          meta: { middleware: auth }
        },
        {
          path: "Clausulaservico-management/edit-Clausulaservico/:id",
          name: "Edit Clausulaservico",
          components: { default: EditClausulaservicoPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipopessoa-management/list-Tipopessoa",
          name: "List Tipopessoas",
          components: { default: ListTipopessoaPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipopessoa-management/add-Tipopessoa",
          name: "Add Tipopessoa",
          components: { default: AddTipopessoaPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipopessoa-management/edit-Tipopessoa/:id",
          name: "Edit Tipopessoa",
          components: { default: EditTipopessoaPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipocontrato-management/list-Tipocontrato",
          name: "List Tipocontratos",
          components: { default: ListTipocontratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipocontrato-management/add-Tipocontrato",
          name: "Add Tipocontrato",
          components: { default: AddTipocontratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Tipocontrato-management/edit-Tipocontrato/:id",
          name: "Edit Tipocontrato",
          components: { default: EditTipocontratoPage },
          meta: { middleware: auth }
        },
        {
          path: "Logradourotipo-management/list-Logradourotipo",
          name: "List Logradourotipos",
          components: { default: ListLogradourotipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Logradourotipo-management/add-Logradourotipo",
          name: "Add Logradourotipo",
          components: { default: AddLogradourotipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Logradourotipo-management/edit-Logradourotipo/:id",
          name: "Edit Logradourotipo",
          components: { default: EditLogradourotipoPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguradora-management/list-Seguradora",
          name: "List Seguradoras",
          components: { default: ListSeguradoraPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguradora-management/add-Seguradora",
          name: "Add Seguradora",
          components: { default: AddSeguradoraPage },
          meta: { middleware: auth }
        },
        {
          path: "Seguradora-management/edit-Seguradora/:id",
          name: "Edit Seguradora",
          components: { default: EditSeguradoraPage },
          meta: { middleware: auth }
        },
        {
          path: "Apolicestatu-management/list-Apolicestatu",
          name: "List Apolicestatus",
          components: { default: ListApolicestatuPage },
          meta: { middleware: auth }
        },
        {
          path: "Apolicestatu-management/add-Apolicestatu",
          name: "Add Apolicestatu",
          components: { default: AddApolicestatuPage },
          meta: { middleware: auth }
        },
        {
          path: "Apolicestatu-management/edit-Apolicestatu/:id",
          name: "Edit Apolicestatu",
          components: { default: EditApolicestatuPage },
          meta: { middleware: auth }
        },
        {
          path: "Tenant-management/list-Tenant",
          name: "List Tenants",
          components: { default: ListTenantPage },
          meta: { middleware: auth }
        },
        {
          path: "Tenant-management/add-Tenant",
          name: "Add Tenant",
          components: { default: AddTenantPage },
          meta: { middleware: auth }
        },
        {
          path: "Tenant-management/edit-Tenant/:id",
          name: "Edit Tenant",
          components: { default: EditTenantPage },
          meta: { middleware: auth }
        },
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  componentsMenu,
  examplesMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  appMenu,
  {
    path: "/",
    component: DashboardLayout,
    name: "Dashboard layout",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { middleware: auth }
      },
      {
        path: "alternative",
        name: "Alternative",
        component: AlternativeDashboard,
        meta: {
          navbarType: "light"
        }
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts
      },
      {
        path: "widgets",
        name: "Widgets",
        component: Widgets
      }
    ]
  },
  authPages
];

export default routes;
