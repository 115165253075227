<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h2 class="mb-0 ml-4">Editar imóvel</h2>
            </div>
            <div class="col-4 text-right"  v-if="!isInsideModal" >
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Voltar para lista</base-button
              >
            </div>
          </div>
          <div class="card-body">
              
                </div>
                <div class="card-body">
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom"></h3></div>
                
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="codimovelInput"
        label="Cod. do Imóvel"
        prepend-icon="fas fa-user"
        v-model="imovel.codimovel"
        
        v-mask="'####-###'"
        />
        
        <validation-error :errors="apiValidationErrors.codimovel" />
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
    <base-input label="Proprietário">
    <el-select 
        v-model="proprietarios" 
        :tabindex="4"
        multiple 
        filterable
        placeholder="Selecione..."
        
    >
    <el-option
        v-for="single_proprietario in all_proprietarios"
        :key="single_proprietario.id"
        :value="single_proprietario.id"
        :label="single_proprietario.name"
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.proprietario" />
</div>
                    <div class="input-group-append">
                        <base-button icon type="primary" @click="isModalClienteVisible = true" style="margin:30px 0PX 25PX 0PX;padding:0;">
                        <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
                        </base-button>
                    </div>
                    </div>
                    <div class="row">
                        <blockquote ref="display_contrato" style="padding:0;padding-left:10px;margin-left:20px;border-left:3px solid #777;"></blockquote>
                    
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="1"
        ref="cepInput"
        label="CEP"
        prepend-icon="fas fa-user"
        v-model="imovel.cep"
        @change="buscarcep()"
        v-mask="'##.###-###'" 
        />
        
        <validation-error :errors="apiValidationErrors.cep" />
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">              
    <base-input label="tipo logradouro" :disabled="apiValidationErrors.cep === null && imovel.logradourotipo.id !== null">
    <el-select
    name="logradourotipo"
    ref="logradourotipoInput"
    :tabindex="5"
    v-model="imovel.logradourotipo.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    
    :disabled="apiValidationErrors.cep === null && imovel.logradourotipo.id !== null"
    >
    <el-option
        v-for="single_logradourotipo in all_logradourotipos"
        :key="single_logradourotipo.id"
        :value="single_logradourotipo.id"
        :label="single_logradourotipo.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.logradourotipo" />
    <div ref="display_logradourotipo"></div>
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="6"
        ref="logradouroInput"
        label="Logradouro"
        prepend-icon="fas fa-user"
        v-model="imovel.logradouro"
        
        :readonly="apiValidationErrors.cep === null && imovel.logradouro.length > 3"
        />
        
        <validation-error :errors="apiValidationErrors.logradouro" />
</div><div class="col-md-4">
        <base-input
        :tabindex="6"
        ref="bairroInput"
        label="bairro"
        prepend-icon="fas fa-user"
        v-model="imovel.bairro"
        
        :readonly="apiValidationErrors.cep === null && imovel.bairro.length > 3"
        />
        
        <validation-error :errors="apiValidationErrors.bairro" />
</div><div class="col-md-4">              
    <base-input label="cidade" :disabled="apiValidationErrors.cep === null && imovel.municipio.id !== null">
    <el-select
    name="municipio"
    ref="municipioInput"
    :tabindex="7"
    v-model="imovel.municipio.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    
    :disabled="apiValidationErrors.cep === null && imovel.municipio.id !== null"
    >
    <el-option
        v-for="single_municipio in all_municipios"
        :key="single_municipio.id"
        :value="single_municipio.id"
        :label="single_municipio.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.municipio" />
    <div ref="display_municipio"></div>
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="2"
        ref="numeroInput"
        label="nº"
        prepend-icon="fas fa-user"
        v-model="numeroFormatado"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.numero" />
</div><div class="col-md-4">
        <base-input
        :tabindex="3"
        ref="complementoInput"
        label="Complemento"
        prepend-icon="fas fa-user"
        v-model="imovel.complemento"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.complemento" />
</div>
            </div>
        
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Salvar Alterações
                </base-button>
              </div>
          </div>
          </form>
        </card>
      </div>
    </div>
  
                        <Modal :show.sync="isModalClienteVisible" class="modal-overlay">  
                            
                            <AddCliente :isInsideModal="true" @close-modal="isModalClienteVisible = false" :insideModalOnSave="'getProprietarios'" @callFunction="handleFunctionCall"></AddCliente>
                        </Modal>
                    
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from '@/components/BaseSlider';
import Modal from '@/components/Modal.vue';
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from 'vue-the-mask';
import axios from 'axios';

import AddCliente from "@/views/app/ClienteManagement/AddClientePage.vue";



export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    
AddCliente,
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
      isInsideModal: {
        type: Boolean,
        default: false,
      },
      insideModalOnSave: {
        type: String,
        required: false,
        default: null
      }
    },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,
      
      isModalClienteVisible: false,
      proprietarios: [],
      all_proprietarios: [],
      all_logradourotipos: [],
      all_municipios: [],
      
      
      
      clientes_filter_itens: {pessoavinculo_id: [1,3]},
      imovel: {
        type: "imovels",
        
        codimovel:'',
        cep:'',
        logradouro:'',
        bairro:'',
        numero:'',
        complemento:'',
        
            proprietarios: [],
            logradourotipo: {
                type: "logradourotipos",
                id: null
                },
            municipio: {
                type: "municipios",
                id: null
                },
        relationshipNames: ["proprietarios","logradourotipo","municipio"],
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["proprietarios","logradourotipo","municipio"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    
                            numeroFormatado: {
                                get() {
                                    return this.numToBr(this.imovel.numero);
                                },
                                set(valor) {
                                    this.imovel.numero = this.numToUs(valor);
                                }
                            },
  },
  watch: {
    
  },
  created() {
    this.get();
    
    this.getProprietarios();
    this.getLogradourotipo();
    this.getMunicipio();
  },

  methods: {
    
    async get() {
        try {
          console.log('data0: ', this.imovel);
          const id = this.$route.params.id;
          await this.$store.dispatch("imovels/get", id);

          let apiData = this.$store.getters["imovels/imovel"];

          for (let key in apiData) {
              if (apiData[key] === null && this.imovel[key]?.id==null) {
                  apiData[key] = this.imovel[key];
              }
          }
          this.imovel = apiData;
          console.log('data5: ', this.imovel);

  
          
          
          this.imovel.proprietarios.forEach((proprietario) => {
              this.proprietarios.push(proprietario.id+"");
          });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Oops, something went wrong!`,
          });
          this.setApiValidation(error.response.data.errors);
        }
      },
async getProprietarios(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.clientes_filter_itens ? this.clientes_filter_itens : {}),
        },
      };
      //if(this.all_proprietarios || Object.keys(this.all_proprietarios).lenght == undefined){
        await this.$store.dispatch("clientes/list", params);
        this.all_proprietarios = await this.$store.getters["clientes/dropdown"];
      //}
      

      if (this.all_proprietarios && this.all_proprietarios.length) {
       // this.imovel.proprietarios.id = this.all_proprietarios[0].id;
        if(idn !== null){
            this.proprietarios.push(idn+"");            this.$refs.proprietariosInput.$emit('change', idn);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getLogradourotipo(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.logradourotipos_filter_itens ? this.logradourotipos_filter_itens : {}),
        },
      };
      //if(this.all_logradourotipos || Object.keys(this.all_logradourotipos).lenght == undefined){
        await this.$store.dispatch("logradourotipos/list", params);
        this.all_logradourotipos = await this.$store.getters["logradourotipos/dropdown"];
      //}
      

      if (this.all_logradourotipos && this.all_logradourotipos.length) {
       // this.imovel.logradourotipo.id = this.all_logradourotipos[0].id;
        if(idn !== null){
            this.imovel.logradourotipo.id = idn;            this.$refs.logradourotipoInput.$emit('change', this.imovel.logradourotipo.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getMunicipio(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.municipios_filter_itens ? this.municipios_filter_itens : {}),
        },
      };
      //if(this.all_municipios || Object.keys(this.all_municipios).lenght == undefined){
        await this.$store.dispatch("municipios/list", params);
        this.all_municipios = await this.$store.getters["municipios/dropdown"];
      //}
      

      if (this.all_municipios && this.all_municipios.length) {
       // this.imovel.municipio.id = this.all_municipios[0].id;
        if(idn !== null){
            this.imovel.municipio.id = idn;            this.$refs.municipioInput.$emit('change', this.imovel.municipio.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
    

    async buscarcep(){
  try {
      const b_cep = await axios.get(`https://viacep.com.br/ws/${this.imovel.cep.replace(/\D/g, '')}/json/`);

      //preenche variaveis
      this.imovel.logradouro = b_cep.data.logradouro;
      this.imovel.bairro = b_cep.data.bairro;
      this.imovel.municipio.id = b_cep.data.ibge;
      this.imovel.logradourotipo.id = null;
      //seleciona tipo de logradouro
      var last_qtd = 0;
      var logra_prov='###'+b_cep.data.logradouro;
      var logra_new=logra_prov;

      for (const [key, logradourotipo_value] of Object.entries(this.all_logradourotipos)) {
          if (logra_prov.toUpperCase().indexOf('###'+logradourotipo_value.name.toUpperCase()) === 0) {
              if (logradourotipo_value.name.length > last_qtd) {
                  last_qtd = logradourotipo_value.name.length;
                  this.imovel.logradourotipo.id = logradourotipo_value.id;

                  //elemina tipo de logradouro do logradouro
                  var logra_new=logra_prov;
                  logra_new = logra_new.replace(new RegExp('###'+logradourotipo_value.name, "gi"), '');
                  logra_new=logra_new.trim();
              }
          }
      }

      logra_new=logra_new.replace('###','');
      this.imovel.logradouro = logra_new;
      this.apiValidationErrors.cep = null;


  } catch (error) {
      this.apiValidationErrors.cep = ['CEP não encontrado'];
      this.imovel.logradouro = '';
      this.imovel.bairro = '';
      this.imovel.municipio.id = null;
      this.imovel.logradourotipo.id = null;
      //console.error('Erro ao buscar endereço:', error);
  }
},
    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
        // Verifica se o valor termina com um ponto
        let sulfix='';
        if (valor.toString().endsWith('.')) {
          sulfix=',';
        }
        
      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split('.')[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais
      });
      return (valorFormatado.toString()+sulfix);

    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
        let valorFormatado = valorBrasileiro.replace(/\./g, '').replace(/,/g, '.');
        // Verifica se o último caractere é uma vírgula
        return (valorFormatado);
    },

    goBack() {
      this.$router.push({ name: "List Imovels" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }
      
      //this.imovel.excerpt = this.imovel.description;
      /**
      this.imovel.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.imovel.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar=Object.assign({}, this.imovel);
        console.log('de1', dados_enviar);
        if(dados_enviar.proprietarios.length==0){
          delete dados_enviar.proprietarios;
        }
        if(dados_enviar.logradourotipo?.id==null){
          delete dados_enviar.logradourotipo;
        }
        if(dados_enviar.municipio?.id==null){
          delete dados_enviar.municipio;
        }
        
if(dados_enviar.cep){dados_enviar.cep=dados_enviar.cep.replace(/\D/g, '');}




      dados_enviar.proprietarios = [];
      this.proprietarios.forEach((proprietario) => {
        dados_enviar.proprietarios.push({
          id: proprietario,
          type: "clientes",
        });
      });

        if(dados_enviar.created_at){delete dados_enviar.created_at;}
        if(dados_enviar.updated_at){delete dados_enviar.updated_at;}
        if(dados_enviar.deleted_at){delete dados_enviar.deleted_at;}
        if(dados_enviar.tenants){delete dados_enviar.tenants;}

console.log(dados_enviar);
        if(!this.$route.params.id){
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("imovels/add", dados_enviar);
          this.imovel = await this.$store.getters["imovels/imovel"];
        }else{
          //atualizar registro
          console.log(dados_enviar);
          await this.$store.dispatch("imovels/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("imovels/upload", {
            imovel: this.imovel,
            image: this.file,
          });

          this.imovel.image = await this.$store.getters["imovels/url"];

          await this.$store.dispatch("imovels/update", this.imovel);
          this.imovel = await this.$store.getters["imovels/Imovel"];
        }

        
        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Imóveis Adicionado com sucesso.",
        });

        if(!this.isInsideModal){
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        }else{
          //fecha modal
          this.$emit('close-modal');
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if(this.insideModalOnSave!==null){
            this.$emit('callFunction', { functionName: this.insideModalOnSave, args: [this.imovel.id] });
          }
        }


      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.imovel.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch("imovels/destroy", this.imovel.id);
        }

        this.imovel.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },
  
    mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll('span');

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute('tabindex');
    });


    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
