import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
// import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import interesses from "./modules/interesses-module";
import roles from "./modules/roles-module";
import permissions from "./modules/permissions-module";
import categories from "./modules/categories-module";
import tags from "./modules/tags-module";
import items from "./modules/items-module";
import reset from "./modules/reset";
import tenants from "./modules/tenants-module";
import apolicestatus from "./modules/apolicestatus-module";
import seguradoras from "./modules/seguradoras-module";
import logradourotipos from "./modules/logradourotipos-module";
import tipocontratos from "./modules/tipocontratos-module";
import tipopessoas from "./modules/tipopessoas-module";
import clausulaservicos from "./modules/clausulaservicos-module";
import seguroistatus from "./modules/seguroistatus-module";
import situcontratos from "./modules/situcontratos-module";
import municipios from "./modules/municipios-module";
import tipotelefones from "./modules/tipotelefones-module";
import requesttipos from "./modules/requesttipos-module";
import seguroiformapagtos from "./modules/seguroiformapagtos-module";
import estadocivils from "./modules/estadocivils-module";
import pessoaaes from "./modules/pessoaaes-module";
import pessoasexos from "./modules/pessoasexos-module";
import pessoavinculos from "./modules/pessoavinculos-module";
import pessoajuridicatipos from "./modules/pessoajuridicatipos-module";
import seguroiprevnumeroparcelas from "./modules/seguroiprevnumeroparcelas-module";
import imoveltipos from "./modules/imoveltipos-module";
import metodocalculos from "./modules/metodocalculos-module";
import clientes from "./modules/clientes-module";
import imovels from "./modules/imovels-module";
import seguroiparcelamentos from "./modules/seguroiparcelamentos-module";
import contratos from "./modules/contratos-module";
import seguroincendios from "./modules/seguroincendios-module";
import apirequests from "./modules/apirequests-module";
import seguroicontratars from "./modules/seguroicontratars-module";
import seguroicancelars from "./modules/seguroicancelars-module";
import faturas from "./modules/faturas-module";
import dashboards from "./modules/dashboards-module";
import preferencias from "./modules/preferencias-module";
import faturaextratos from "./modules/faturaextratos-module";
//module_list_import

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    // alerts,
    profile,
    users,
    interesses,
    roles,
    permissions,
    categories,
    tags,
    items,
    reset
,
    tenants,
    apolicestatus,
    seguradoras,
    logradourotipos,
    tipocontratos,
    tipopessoas,
    clausulaservicos,
    seguroistatus,
    situcontratos,
    municipios,
    tipotelefones,
    requesttipos,
    seguroiformapagtos,
    estadocivils,
    pessoasexos,
    pessoaaes,
    pessoavinculos,
    pessoajuridicatipos,
    seguroiprevnumeroparcelas,
    imoveltipos,
    metodocalculos,
    clientes,
    imovels,
    seguroiparcelamentos,
    contratos,
    seguroincendios,
    apirequests,
    seguroicontratars,
    seguroicancelars,
    faturas,
    dashboards,
    preferencias,
    faturaextratos
    //module_list_var
  }
});
