function addTawkToScript() {
    var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/659b5a3d8d261e1b5f5090e4/1hjjdh0pj';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }
  
  export default {
    install(Vue, options) {
      // Adicione o script do Tawk.to ao carregar o aplicativo
      addTawkToScript();
  
      // Adicione um método global para configurar o Tawk.to
      Vue.prototype.$initializeTawkTo = () => {
        if (typeof window !== "undefined") {
          window.Tawk_API = window.Tawk_API || {};
          var Tawk_LoadStart = new Date();
  
          window.Tawk_API.onLoad = () => {
            // Configure as informações do usuário após o carregamento
            if (options && options.isAuthenticated && options.user) {
              window.Tawk_API.setAttributes({
                'name'  : options.user.name,
                'email' : options.user.email,
                // ... você pode adicionar mais atributos aqui
              }, function(error){});
            }
          };
        }
      };
    }
  };