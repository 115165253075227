import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/contratos?include=imovel,inquilino,tipocontrato,situcontrato,imoveltipo,seguroistatu,seguroincendio`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .get(`${url}/contratos/${id}?include=imovel,imovel.municipio,imovel.municipio.latestPinicupe,inquilino,inquilino.tipopessoa,tipocontrato,situcontrato,imoveltipo,seguroistatu`, options)
    .then(response => {
      let contrato = jsona.deserialize(response.data);
      delete contrato.links;
      return contrato;
    });
}

function add(contrato) {
  const payload = jsona.serialize({
    stuff: contrato,
    includeNames: ["categories"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  //.post(`${url}/contratos?include=imovel,inquilino,tipocontrato,situcontrato,imoveltipo,seguroistatu`, payload, options)
  return axios
    .post(`${url}/contratos?include=imovel,inquilino,tipocontrato,situcontrato,imoveltipo,seguroistatu`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(contrato) {
  const payload = jsona.serialize({
    stuff: contrato,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  return axios
    .patch(`${url}/contratos/${contrato.id}?include=imovel,inquilino,tipocontrato,situcontrato,imoveltipo,seguroistatu`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/contratos/${id}`, options);
}
function downloadPDF(tipo, arquivo) {
  return axios.get(`${url}/downloads/${tipo}/${arquivo}.pdf`, { responseType: 'blob' }).then((response) => {
    return response.data;
  });
}
/*
function upload(contrato, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/contratos/${contrato.id}/image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}
export default {
  list,
  get,
  add,
  update,
  destroy,
  upload
};
*/
export default {
  list,
  get,
  add,
  update,
  destroy,
  downloadPDF
};