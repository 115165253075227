import service from '@/store/services/clientes-service';

const state = {
  list: {},
  cliente: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, cliente) => {
    state.cliente = cliente;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((cliente) => { commit('SET_RESOURCE', cliente); });
  },

  baixarArquivo({ commit, dispatch }, params) {
    // Chama a função do serviço para baixar o PDF
      return service.downloadPDF(params[0],params[1])
        .then((resposta) => {
          // Manipular a resposta, se necessário
          //console.log("PDF baixado com sucesso!", resposta);
          return resposta;
        })
        .catch((erro) => {
          // Lidar com erros, como usuário não autenticado
          console.error("Erro ao baixar a apólice:", erro);
          throw erro;
        });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((cliente) => { commit('SET_RESOURCE', cliente); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((cliente) => { commit('SET_RESOURCE', cliente); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  cliente: state => state.cliente,
  dropdown: (state) => {
    return state.list.map(cliente => ({
      id: cliente.id,
      name: cliente.nome,
      codcliente: cliente.codcliente,
      cpfcnpj: cliente.cpfcnpj,
      datanascimento: cliente.datanascimento,
      pessoavinculo_id: cliente.pessoavinculo?.id,
      nome: cliente.nome,
      tipopessoa_id: cliente.tipopessoa?.id,
      pessoajuridicatipo_id: cliente.pessoajuridicatipo?.id,
      estadocivil_id: cliente.estadocivil?.id,
      pessoasexo_id: cliente.pessoasexo?.id,
      tipotelefone_id: cliente.tipotelefone?.id,
      telefone: cliente.telefone,
      email: cliente.email,
      cep: cliente.cep,
      logradourotipo_id: cliente.logradourotipo?.id,
      logradouro: cliente.logradouro,
      bairro: cliente.bairro,
      municipio_id: cliente.municipio?.id,
      numero: cliente.numero,
      complemento: cliente.complemento
    }));
  }
};

const clientes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default clientes;
