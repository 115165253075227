<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Lista de Locações</h3>
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addContrato()">
                <span class="btn-inner--icon"
                  ><i class="fas fa-house-user"></i
                ></span>
                <span class="btn-inner--text">Nova Locação</span>
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Pesquisar..."
                clearable
              />
            </div>
          </div>

          <ul class="nav nav-tabs nav-fill">
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 0 ? 'active' : '', 'nav-link']"
                href="#"
                @click="
                  navitem_at = 0;
                  getList();
                "
                >Todos</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 1 ? 'active' : '', 'nav-link', navitem_total[1]? '' : 'disabled' ]"
                href="#"
                @click="
                  navitem_at = 1;
                  getList();
                "
                >
                  <badge 
                    class="mr-2" 
                    type="warning" 
                    v-if="navitem_total[1]"
                    >{{navitem_total[1]}}
                  </badge>
                  Orçar</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 2 ? 'active' : '', 'nav-link', navitem_total[2]? '' : 'disabled']"
                href="#"
                @click="
                  navitem_at = 2;
                  getList();
                "
                >
                  <badge 
                    class="mr-2" 
                    type="warning" 
                    v-if="navitem_total[2]"
                    >{{navitem_total[2]}}
                  </badge>
                  Contratar</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 3 ? 'active' : '', 'nav-link', navitem_total[3]? '' : 'disabled']"
                href="#"
                @click="
                  navitem_at = 3;
                  getList();
                "
                >
                  <badge 
                    class="mr-2" 
                    type="warning" 
                    v-if="navitem_total[3]"
                    >{{navitem_total[3]}}
                  </badge>
                  Renovar</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 4 ? 'active' : '', 'nav-link', navitem_total[4]? '' : 'disabled']"
                href="#"
                @click="
                  navitem_at = 4;
                  getList();
                "
                >
                  <badge 
                    class="mr-2" 
                    type="warning" 
                    v-if="navitem_total[4]"
                    >{{navitem_total[4]}}
                  </badge>
                  Apólice Ativa</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 5 ? 'active' : '', 'nav-link', navitem_total[5]? '' : 'disabled']"
                href="#"
                @click="
                  navitem_at = 5;
                  getList();
                "
                >
                  <badge 
                    type="warning" 
                    v-if="navitem_total[5]"
                    >{{navitem_total[5]}}
                  </badge>
                  Cancelar</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 6 ? 'active' : '', 'nav-link', navitem_total[6]? '' : 'disabled']"
                href="#"
                @click="
                  navitem_at = 6;
                  getList();
                "
                >
                  <badge 
                    type="warning" 
                    v-if="navitem_total[6]"
                    >{{navitem_total[6]}}
                  </badge>
                  Apólice Inativa</a
              >
            </li>
            <li class="nav-item">
              <a
                v-bind:class="[navitem_at == 7 ? 'active' : '', 'nav-link', navitem_total[7]? '' : 'disabled']"
                href="#"
                @click="
                  navitem_at = 7;
                  getList();
                "
                >
                  <badge 
                    type="warning" 
                    v-if="navitem_total[7]"
                    >{{navitem_total[7]}}
                  </badge>
                  FALHA</a
              >
            </li>
          </ul>

          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="contratos"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Código"
              min-width="90px"
              prop="codigo"
              sortable="custom"
            />
            <el-table-column
              label="Imóvel"
              min-width="200px"
              prop="imovel.codimovel"
            >
              <template v-slot="{ row }">
                <div>
                  <span
                    >{{ row.imovel.codimovel }}: {{ row.imovel.logradouro }} nº
                    {{ row.imovel.numero }} - {{ row.imovel.bairro }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Seguro"
              min-width="125px"
              prop="seguroistatu.status"
            >
              <template v-slot="{ row }">  
                <div>
                    <div
                      v-if="!['2', '5', '6', '13', '17'].includes(row.seguroistatu.id)"
                      >{{row.seguroistatu.status}}</div
                    >
                    <div class="ml-0" v-if="['5', '6', '13', '17'].includes(row.seguroistatu.id)">   
                          <base-button class="ml-0" type="warning"  size="sm"
                           @click="orcarSegIContrato(row)" 
                          >
                            <i class="fas fa-house-fire"></i>Clique para Cotar
                          </base-button>
                    </div>
                    <div class="ml-0" v-if="['2'].includes(row.seguroistatu.id)">   
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="contratarSeguroincendio(row.seguroincendio[0])"
                            v-if="row.seguroincendio.length==1
                            && row.seguroistatu.id == 2 
                            && !is_vencido(row.seguroincendio[0].validade) 
                            && !is_vencido(row.seguroincendio[0].datainicio)"
                          >
                            <i class="fas fa-handshake"></i>Clique para Contratar
                          </base-button>
                          
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="listaOrcamento(row)"
                            v-if="row.seguroincendio.length==1
                            && ['2','17'].includes(row.seguroistatu.id) 
                            &&(is_vencido(row.seguroincendio[0].datainicio) || is_vencido(row.seguroincendio[0].validade) )"
                          >
                            <i class="fas fa-handshake"></i>Orçamento Expirado
                          </base-button>
                          

                          
                          <base-button class="ml-0" type="warning"  size="sm" 
                            @click="listaOrcamento(row)"
                            v-if="row.seguroincendio.length>1"
                          >
                            <i class="fas fa-handshake"></i>Opções de Contratação
                          </base-button>
                    </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column min-width="50px" align="right">
              <template v-slot="{row}">
                  <el-dropdown trigger="click" class="dropdown">
                  <span class="btn btn-sm btn-icon-only">
                    <i class="fas fa-ellipsis-v mt-2"></i>
                  </span>
                      <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" style="cursor:pointer" @click="orcarSegIContrato(row)" 
                            v-if="['5', '6', '13', '17'].includes(row.seguroistatu.id)">
                            <i class="fas fa-house-fire"></i>
                            Cotar Seguro Incêndio </a
                          >
                          <a class="dropdown-item" style="cursor:pointer" @click="editContrato(row)"><i class="fas fa-user-edit"></i>Editar contrato</a>
                          <a class="dropdown-item" style="cursor:pointer" @click="deleteContrato(row)"><i class="fas fa-trash"></i>Remover Contrato</a>
                      </el-dropdown-menu>
                  </el-dropdown>
              </template>
              
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ total ? from + 1 : 0 }} até {{ to }} de um total de
              {{ total }} registros.

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      contratos: [],
      navitem_at: 0,
      navitem_filtros: [
        {},
        { seguroistatu_id: [1, 5, 6, 13, 17] },
        { seguroistatu_id: [2, 3, 7, 8, 12] },
        { seguroistatu_id: [15, 16] },
        { seguroistatu_id: 4 },
        { seguroistatu_id: 14 },
        { seguroistatu_id: 10 },
        { seguroistatu_id: [6, 7] },
      ],
      navitem_total:[],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    
    getListDebounced: _.debounce(function () {
      this.getList();
      this.contagemTopicos();
    }, 300),

    formatDate(dateStr) {
      const date = new Date(dateStr + 'T00:00');
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("pt-BR", options);
    },
    
    is_vencido(validade){
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      validade = new Date(validade + 'T00:00');
      return validade < hoje;
    },
    async AlterarDataInicio(row) {
      if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
        this.$notify({
          type: "danger",
          message:
            "Você não esta habilitado para alterar a data",
        });
        return;
      }
      try {
        const { value: date } = await swal.fire({
          title: `Data de inicio de vigencia do seguro`,
          input: "date",
          inputValidator: (value) => {
            if (!value) {
              return "Você precisa selecionar uma data!";
            }

            //verifica se é data futura
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            var inicio = new Date(value + 'T00:00');
            if (inicio < hoje) {
              return "A data deve ser maior ou igual a hoje!";
            }
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Salvar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (date) {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: date,
            contrato: row.contrato,
            clausulaservico: row.clausulaservico,
            metodocalculo: row.metodocalculo,
            relationshipNames: [
              "contrato",
              "clausulaservico",
              "metodocalculo"
            ],
          };
          

          await this.$store.dispatch("seguroincendios/updatedata", seguroincendio);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para nova cotação.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async Atualizarorcamento(row){
      try {
          var seguroincendio = {
            type: "seguroincendios",
            id: row.id,
            datainicio: null,
            contrato: row.contrato,
            clausulaservico: row.clausulaservico,
            metodocalculo: row.metodocalculo,
            relationshipNames: [
              "contrato",
              "clausulaservico",
              "metodocalculo"
            ],
          };
          
          await this.$store.dispatch("seguroincendios/updatedata", seguroincendio);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Dados enviados para atualizar cotação.",
          });
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "This Tag still has associated Items.",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.navitem_filtros[this.navitem_at]
              ? this.navitem_filtros[this.navitem_at]
              : {}),
            ...(this.query ? { codigo: this.query } : {}),
            ...(this.query ? { datainicio: this.query } : {}),
            ...(this.query ? { valorlocacao: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "imovel,seguroistatu,seguroincendio",
        };
        await this.$store.dispatch("contratos/list", params).then(() => {
          this.contratos = this.$store.getters["contratos/list"];
          this.total = this.$store.getters["contratos/listTotal"];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async contagemTopicos(){
      try {
        for(let iii=1;iii<this.navitem_filtros.length;iii++){
          let params = {
            filter: {...(this.navitem_filtros[iii] ? this.navitem_filtros[iii] : {})},
            page: {number: 1,size: 1,},
            include: "",
          };
          await this.$store.dispatch("contratos/list", params).then(() => {
            this.$set(this.navitem_total, iii, this.$store.getters["contratos/listTotal"]);
          });
        }
        
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    orcarSegIContrato(row) {
      this.$router.push({
        name: "Add Seguroincendio",
        params: { contrato: row.id },
      });
    },
    
     contratarSeguroincendio(row) {
      this.$router.push({
        name: "Add Seguroicontratar",
        params: { seguroincendio_id: row.id },
      });
    },
    editContrato(row) {
      this.$router.push({
        name: "Edit Contrato",
        params: { id: row.id },
      });
    },
    
    listaOrcamento(row) {
      this.$router.push({
        name: "List Seguroincendios",
        params: { contrato_filter: row.id },
      });
    },
    async deleteContrato(row) {
      try {
        const confirmation = await swal.fire({
          title: `Deseja deletar este Contrato?`,
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Sim, deletar!",
          cancelButtonText: "Não, manter",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-warning",
          }
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("contratos/destroy", row.id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Contrato removido com sucesso.",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: "Este contrato esta associado com outros registros",
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, Algo deu errado!",
          });
        }
      }
    },
    addContrato() {
      this.$router.push({ name: "Add Contrato" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
