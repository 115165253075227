<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h2 class="mb-0 ml-4">Editar Locação</h2>
            </div>
            <div class="col-4 text-right"  v-if="!isInsideModal" >
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Voltar para lista</base-button
              >
            </div>
          </div>
          <div class="card-body">
              
                </div>
                <div class="card-body">
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom">Dados da Locação</h3></div>
                
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="codigoInput"
        label="Cod. do Contrato"
        prepend-icon="fas fa-user"
        v-model="contrato.codigo"
        
        v-mask="'####-###/#'"
        />
        
        <validation-error :errors="apiValidationErrors.codigo" />
</div><div class="col-md-4">              
    <base-input label="Imóvel" >
    <el-select
    name="imovel"
    ref="imovelInput"
    :tabindex="0"
    v-model="contrato.imovel.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    
    
    >
    <el-option
        v-for="single_imovel in all_imovels"
        :key="single_imovel.id"
        :value="single_imovel.id"
        :label="preferencia && preferencia.imovel && single_imovel.id === preferencia.imovel.id ? `★ ${single_imovel.name}` : single_imovel.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.imovel" />
    <div ref="display_imovel"></div>
</div>
                    <div class="input-group-append">
                        <base-button icon type="primary" @click="isModalImovelVisible = true" style="margin:30px 0PX 25PX 0PX;padding:0;">
                        <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
                        </base-button>
                    </div>
                    </div>
                    <div class="row">
                        <blockquote ref="display_contrato" style="padding:0;padding-left:10px;margin-left:20px;border-left:3px solid #777;"></blockquote>
                    
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">              
    <base-input label="Inquilino" >
    <el-select
    name="inquilino"
    ref="inquilinoInput"
    :tabindex="0"
    v-model="contrato.inquilino.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    
    
    >
    <el-option
        v-for="single_inquilino in all_inquilinos"
        :key="single_inquilino.id"
        :value="single_inquilino.id"
        :label="preferencia && preferencia.inquilino && single_inquilino.id === preferencia.inquilino.id ? `★ ${single_inquilino.name}` : single_inquilino.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.inquilino" />
    <div ref="display_inquilino"></div>
</div>
                    <div class="input-group-append">
                        <base-button icon type="primary" @click="isModalClienteVisible = true" style="margin:30px 0PX 25PX 0PX;padding:0;">
                        <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
                        </base-button>
                    </div>
                    </div>
                    <div class="row">
                        <blockquote ref="display_contrato" style="padding:0;padding-left:10px;margin-left:20px;border-left:3px solid #777;"></blockquote>
                    
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">              
    <base-input label="Tipo de contrato" >
    <el-select
    name="tipocontrato"
    ref="tipocontratoInput"
    :tabindex="0"
    v-model="contrato.tipocontrato.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    @change="getSitucontrato()"
    
    >
    <el-option
        v-for="single_tipocontrato in all_tipocontratos"
        :key="single_tipocontrato.id"
        :value="single_tipocontrato.id"
        :label="preferencia && preferencia.tipocontrato && single_tipocontrato.id === preferencia.tipocontrato.id ? `★ ${single_tipocontrato.name}` : single_tipocontrato.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.tipocontrato" />
    <div ref="display_tipocontrato"></div>
</div><div class="col-md-4">              
    <base-input label="Situação" >
    <el-select
    name="situcontrato"
    ref="situcontratoInput"
    :tabindex="0"
    v-model="contrato.situcontrato.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    @change="getImoveltipo()"
    
    >
    <el-option
        v-for="single_situcontrato in all_situcontratos"
        :key="single_situcontrato.id"
        :value="single_situcontrato.id"
        :label="preferencia && preferencia.situcontrato && single_situcontrato.id === preferencia.situcontrato.id ? `★ ${single_situcontrato.name}` : single_situcontrato.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.situcontrato" />
    <div ref="display_situcontrato"></div>
</div><div class="col-md-4">              
    <base-input label="Tipo de imóvel/Atividade" >
    <el-select
    name="imoveltipo"
    ref="imoveltipoInput"
    :tabindex="0"
    v-model="contrato.imoveltipo.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    @change="getMetodocalculo()"
    
    >
    <el-option
        v-for="single_imoveltipo in all_imoveltipos"
        :key="single_imoveltipo.id"
        :value="single_imoveltipo.id"
        :label="preferencia && preferencia.imoveltipo && single_imoveltipo.id === preferencia.imoveltipo.id ? `★ ${single_imoveltipo.name}` : single_imoveltipo.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.imoveltipo" />
    <div ref="display_imoveltipo"></div>
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
    <base-input label="Data inicial">
        <flat-picker
        :tabindex="0"
        
        :config="{ 
            allowInput: true, 
            dateFormat: 'Y-m-d',
            altInput: true,
            altFormat: 'd/m/Y'
            
        }"
        class="form-control datepicker"
        v-model="contrato.datainicio"
        >
        </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.datainicio" />
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="valorlocacaoInput"
        label="Valor do aluguel"
        prepend-icon="fa fa-square-o"
        v-model="valorlocacaoFormatado"
        @change="calculacobertura()"
        
        />
        
        <validation-error :errors="apiValidationErrors.valorlocacao" />
</div></div>
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom"></h3></div>
                <div class="row">
            </div>
        
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Salvar Alterações
                </base-button>
              </div>
          </div>
          </form>
        </card>
      </div>
    </div>
  
                        <Modal :show.sync="isModalImovelVisible" class="modal-overlay">  
                            
                            <AddImovel :isInsideModal="true" @close-modal="isModalImovelVisible = false" :insideModalOnSave="'getImovel'" @callFunction="handleFunctionCall"></AddImovel>
                        </Modal>
                    
                        <Modal :show.sync="isModalClienteVisible" class="modal-overlay">  
                            
                            <AddCliente :isInsideModal="true" @close-modal="isModalClienteVisible = false" :insideModalOnSave="'getInquilino'" @callFunction="handleFunctionCall"></AddCliente>
                        </Modal>
                    
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from '@/components/BaseSlider';
import Modal from '@/components/Modal.vue';
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from 'vue-the-mask';
import axios from 'axios';

import AddImovel from "@/views/app/ImovelManagement/AddImovelPage.vue";
import AddCliente from "@/views/app/ClienteManagement/AddClientePage.vue";



export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    
AddImovel,
AddCliente,
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
      isInsideModal: {
        type: Boolean,
        default: false,
      },
      insideModalOnSave: {
        type: String,
        required: false,
        default: null
      }
    },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,
      
      isModalImovelVisible: false,
      isModalClienteVisible: false,
      all_imovels: [],
      all_inquilinos: [],
      all_tipocontratos: [],
      all_situcontratos: [],
      all_imoveltipos: [],
      
      
      
      clientes_filter_itens: {pessoavinculo_id: [2,3]},
      tipocontratos_filter_itens: {id: [1,2]},
      contrato: {
        type: "contratos",
        
        codigo:'',
        datainicio:'',
        valorlocacao:'',
        
            imovel: {
                type: "imovels",
                id: null
                },
            inquilino: {
                type: "clientes",
                id: null
                },
            tipocontrato: {
                type: "tipocontratos",
                id: null
                },
            situcontrato: {
                type: "situcontratos",
                id: null
                },
            imoveltipo: {
                type: "imoveltipos",
                id: null
                },
        relationshipNames: ["imovel","inquilino","tipocontrato","situcontrato","imoveltipo"]
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["imovel","inquilino","tipocontrato","situcontrato","imoveltipo"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    
                            valorlocacaoFormatado: {
                                get() {
                                    return this.numToBr(this.contrato.valorlocacao);
                                },
                                set(valor) {
                                    this.contrato.valorlocacao = this.numToUs(valor);
                                }
                            },
  },
  watch: {
    
  },
  created() {
    this.get();
    
    this.getImovel();
    this.getInquilino();
    this.getTipocontrato();
    this.getSitucontrato();
    this.getImoveltipo();
  },

  methods: {
    
    async get() {
        try {
          const id = this.$route.params.id;
          await this.$store.dispatch("contratos/get", id);

          let apiData = this.$store.getters["contratos/contrato"];

          for (let key in apiData) {
              if (apiData[key] === null && this.contrato[key]?.id==null) {
                  apiData[key] = this.contrato[key];
              }
          }
          this.contrato = apiData;

  
          
        } catch (error) {
          this.$notify({
            type: "danger",
            message: `Oops, something went wrong!`,
          });
          this.setApiValidation(error.response.data.errors);
        }
      },
async getImovel(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.imovels_filter_itens ? this.imovels_filter_itens : {}),
        },
      };
      //if(this.all_imovels || Object.keys(this.all_imovels).lenght == undefined){
        await this.$store.dispatch("imovels/list", params);
        this.all_imovels = await this.$store.getters["imovels/dropdown"];
      //}
      

      if (this.all_imovels && this.all_imovels.length) {
       // this.contrato.imovel.id = this.all_imovels[0].id;
        if(idn !== null){
            this.contrato.imovel.id = idn;            this.$refs.imovelInput.$emit('change', this.contrato.imovel.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getInquilino(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.clientes_filter_itens ? this.clientes_filter_itens : {}),
        },
      };
      //if(this.all_inquilinos || Object.keys(this.all_inquilinos).lenght == undefined){
        await this.$store.dispatch("clientes/list", params);
        this.all_inquilinos = await this.$store.getters["clientes/dropdown"];
      //}
      

      if (this.all_inquilinos && this.all_inquilinos.length) {
       // this.contrato.inquilino.id = this.all_inquilinos[0].id;
        if(idn !== null){
            this.contrato.inquilino.id = idn;            this.$refs.inquilinoInput.$emit('change', this.contrato.inquilino.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getTipocontrato(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.tipocontratos_filter_itens ? this.tipocontratos_filter_itens : {}),
        },
      };
      //if(this.all_tipocontratos || Object.keys(this.all_tipocontratos).lenght == undefined){
        await this.$store.dispatch("tipocontratos/list", params);
        this.all_tipocontratos = await this.$store.getters["tipocontratos/dropdown"];
      //}
      

      if (this.all_tipocontratos && this.all_tipocontratos.length) {
       // this.contrato.tipocontrato.id = this.all_tipocontratos[0].id;
        if(idn !== null){
            this.contrato.tipocontrato.id = idn;            this.$refs.tipocontratoInput.$emit('change', this.contrato.tipocontrato.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getSitucontrato(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.situcontratos_filter_itens ? this.situcontratos_filter_itens : {}),
        },
      };
      //if(this.all_situcontratos || Object.keys(this.all_situcontratos).lenght == undefined){
        await this.$store.dispatch("situcontratos/list", params);
        this.all_situcontratos = await this.$store.getters["situcontratos/dropdown"];
      //}
      
                if (typeof this.contrato.tipocontrato.id != "undefined"){
                    //filtro baseado em campo select
                    var tipocontrato_at=this.contrato.tipocontrato.id;
                    function filtra_situcontrato(value){
                    if(value.tipocontrato_id == tipocontrato_at){
                        return value;
                    }
                    }
                    this.all_situcontratos = this.all_situcontratos.filter(filtra_situcontrato);
                    this.contrato.situcontrato.id=null;
                }else{
                    //filtro baseado em campo input
                    var tipocontrato_at=this.contrato.tipocontrato;
                    function filtra_situcontrato(value){
                    if(value.id == tipocontrato_at){
                        return value;
                    }
                    }
                    this.all_situcontratos = this.all_situcontratos.filter(filtra_situcontrato);
                    this.contrato.situcontrato.id=null;
                }

      if (this.all_situcontratos && this.all_situcontratos.length) {
       // this.contrato.situcontrato.id = this.all_situcontratos[0].id;
        if(idn !== null){
            this.contrato.situcontrato.id = idn;            this.$refs.situcontratoInput.$emit('change', this.contrato.situcontrato.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getImoveltipo(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.imoveltipos_filter_itens ? this.imoveltipos_filter_itens : {}),
        },
      };
      //if(this.all_imoveltipos || Object.keys(this.all_imoveltipos).lenght == undefined){
        await this.$store.dispatch("imoveltipos/list", params);
        this.all_imoveltipos = await this.$store.getters["imoveltipos/dropdown"];
      //}
      
                if (typeof this.contrato.situcontrato.id != "undefined"){
                    //filtro baseado em campo select
                    var situcontrato_at=this.contrato.situcontrato.id;
                    function filtra_imoveltipo(value){
                    if(value.situcontrato_id == situcontrato_at){
                        return value;
                    }
                    }
                    this.all_imoveltipos = this.all_imoveltipos.filter(filtra_imoveltipo);
                    this.contrato.imoveltipo.id=null;
                }else{
                    //filtro baseado em campo input
                    var situcontrato_at=this.contrato.situcontrato;
                    function filtra_imoveltipo(value){
                    if(value.id == situcontrato_at){
                        return value;
                    }
                    }
                    this.all_imoveltipos = this.all_imoveltipos.filter(filtra_imoveltipo);
                    this.contrato.imoveltipo.id=null;
                }

      if (this.all_imoveltipos && this.all_imoveltipos.length) {
       // this.contrato.imoveltipo.id = this.all_imoveltipos[0].id;
        if(idn !== null){
            this.contrato.imoveltipo.id = idn;            this.$refs.imoveltipoInput.$emit('change', this.contrato.imoveltipo.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
    
    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
        // Verifica se o valor termina com um ponto
        let sulfix='';
        if (valor.toString().endsWith('.')) {
          sulfix=',';
        }
        
      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split('.')[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais
      });
      return (valorFormatado.toString()+sulfix);

    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
        let valorFormatado = valorBrasileiro.replace(/\./g, '').replace(/,/g, '.');
        // Verifica se o último caractere é uma vírgula
        return (valorFormatado);
    },

    goBack() {
      this.$router.push({ name: "List Contratos" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }
      
      //this.contrato.excerpt = this.contrato.description;
      /**
      this.contrato.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.contrato.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar=Object.assign({}, this.contrato);
        
        if(dados_enviar.imovel?.id==null){
          delete dados_enviar.imovel;
        }
        if(dados_enviar.inquilino?.id==null){
          delete dados_enviar.inquilino;
        }
        if(dados_enviar.tipocontrato?.id==null){
          delete dados_enviar.tipocontrato;
        }
        if(dados_enviar.situcontrato?.id==null){
          delete dados_enviar.situcontrato;
        }
        if(dados_enviar.imoveltipo?.id==null){
          delete dados_enviar.imoveltipo;
        }
        




        if(dados_enviar.created_at){delete dados_enviar.created_at;}
        if(dados_enviar.updated_at){delete dados_enviar.updated_at;}
        if(dados_enviar.deleted_at){delete dados_enviar.deleted_at;}
        if(dados_enviar.tenants){delete dados_enviar.tenants;}

        if(!this.$route.params.id){
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("contratos/add", dados_enviar);
          this.contrato = await this.$store.getters["contratos/contrato"];
        }else{
          //atualizar registro
          await this.$store.dispatch("contratos/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("contratos/upload", {
            contrato: this.contrato,
            image: this.file,
          });

          this.contrato.image = await this.$store.getters["contratos/url"];

          await this.$store.dispatch("contratos/update", this.contrato);
          this.contrato = await this.$store.getters["contratos/Contrato"];
        }

        
        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Locações Adicionado com sucesso.",
        });

        if(!this.isInsideModal){
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        }else{
          //fecha modal
          this.$emit('close-modal');
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if(this.insideModalOnSave!==null){
            this.$emit('callFunction', { functionName: this.insideModalOnSave, args: [this.contrato.id] });
          }
        }


      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.contrato.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch("contratos/destroy", this.contrato.id);
        }

        this.contrato.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },
  
    mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll('span');

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute('tabindex');
    });


    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
